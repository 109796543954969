import { TABLE_DIM_PAGE_DEFAULT } from "../config/table-settings";
export class Pagetemplatetable {
  TRUE: boolean = true;
  FALSE: boolean = false;
  public filter: any = {};
  public totElementiPagina: number = 0; //Totale elementi della collezione
  public numRighe: number = 0; //Numero righe mostrate
  public numRigheFinoAqui: number = 0; //Contegio righe dall'inizio fino a questa pagina
  public dimPagina: number = TABLE_DIM_PAGE_DEFAULT;  //Numero righe da mostrare
  public numeroPagina: number = 1;  //Pagina corrente
  public numeroPaginaCliccato: number = 0; //Numero di pagina cliccato
  public numColumns: number = 0;  //Numero colonne tabella
  public maxSize: number = 7;

  public isEmpty: boolean = true; //Serve per mostrare la tabella - false: mostra tabella 
  public isSearching: boolean = false; //Serve per mostrare lo spinner
  public resultNotFound: boolean = false; //Serve per mostrare il messaggio di risultato non trovato

  public entityList: any = [];

  public genericService: any = null;
  public content: string = "content"
  public isVisibleTableBoxInfo: boolean = true;



  constructor(
  ) {
    this.numeroPaginaCliccato = this.numeroPagina;
  }

  setEntity(obj: any) {
    if (obj != null && typeof obj != typeof undefined) {
      this.entityList = obj;
    } else {
      this.entityList = [];
    }
  }

  setTotalElements(obj: any) {
    if (obj.totalElements) {
      this.totElementiPagina = obj.totalElements;
    } else {
      this.totElementiPagina = 0;
    }
  }

  calcNumRigheFinoAqui() {
    this.numRigheFinoAqui = ((this.numeroPagina - 1) * this.dimPagina) + this.numRighe;
  }

  /*  Gestione sort */
  tableConfig: any = {
    thead: []
  };


  sortColumn(indexColumn: number) {
    if (this.tableConfig?.thead) {
      if (this.tableConfig.thead[indexColumn]?.columnSort && this.tableConfig.thead[indexColumn].columnSort == this.TRUE) {
        this.sortColumnCurrentSort(indexColumn);
      }
    }
  }

  sortColumnCurrentSort(indexColumn: any) {
    if (this.tableConfig.thead[indexColumn]?.currentSort) {
      if (
        (this.tableConfig.thead[indexColumn].currentSort.asc == this.FALSE && this.tableConfig.thead[indexColumn].currentSort.desc == this.FALSE) ||
        (this.tableConfig.thead[indexColumn].currentSort.asc == this.TRUE && this.tableConfig.thead[indexColumn].currentSort.desc == this.TRUE)
      ) {
        this.tableConfig.thead[indexColumn].currentSort.asc = this.TRUE;
        this.tableConfig.thead[indexColumn].currentSort.desc = this.FALSE;
      } else if (this.tableConfig.thead[indexColumn].currentSort.asc == this.TRUE) {
        this.tableConfig.thead[indexColumn].currentSort.asc = this.FALSE;
        this.tableConfig.thead[indexColumn].currentSort.desc = this.TRUE;
      } else if (this.tableConfig.thead[indexColumn].currentSort.desc == this.TRUE) {
        this.tableConfig.thead[indexColumn].currentSort.asc = this.FALSE;
        this.tableConfig.thead[indexColumn].currentSort.desc = this.FALSE;
      }
    }
  }

  getClassSortByObj(obj: any) {
    let res = [];
    if (obj?.columnSort != null && typeof obj.columnSort != typeof undefined) {
      if (obj.columnSort == this.TRUE) {
        res.push("sort-by");
        this.objCurrentSort(obj, res);
      }
    }
    return res.join(" ");
  }

  objCurrentSort(obj: any, res: any) {
    if (obj?.columnSort != null && typeof obj.columnSort != typeof undefined && obj.columnSort == this.TRUE) {
      if (obj.currentSort?.asc != null && typeof obj.currentSort?.asc != typeof undefined && obj.currentSort.asc == this.TRUE) { res.push("asc"); }
      if (obj.currentSort?.desc != null && typeof obj.currentSort?.desc != typeof undefined && obj.currentSort.desc == this.TRUE) { res.push("desc"); }
    }
  }

  getFilterSort() {
    let res: any = [];
    if (typeof this.tableConfig.thead != typeof undefined) {
      for (let i = 0; i < this.tableConfig.thead.length; i++) {
        this.getFilterSorting(i, res);
      }
    }
    return res;
  }

  getFilterSorting(i: any, res: any) {
    if (this.tableConfig.thead[i]?.columnSort && this.tableConfig.thead[i].columnSort == this.TRUE) {
      if (this.tableConfig.thead[i].currentSort.asc == this.TRUE || this.tableConfig.thead[i].currentSort.desc == this.TRUE) {
        let direction = "";
        direction = this.tableConfig.thead[i].currentSort.asc ? 'asc' : 'desc';
        res.push(this.tableConfig.thead[i].field + "," + direction);
      }
    }
  }

}
