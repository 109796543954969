import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent {
  baseUrlValorizzazioni = environment.baseUrl.valorizzazioni + 'valorizzazioni';
  baseUrlSetinformativi = environment.baseUrl.setinformativi + 'set-informativi';
  currentUrl: any;
  constructor(
    private router: Router,
    private activatedRoute : ActivatedRoute
  ) {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
          this.currentUrl = e.urlAfterRedirects;
      }
    })
  }

}
