import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
/**
 * global configuration for NgbModal Bootstrap
 */
export const DIALOG_MEDIUM_CONFIG: NgbModalOptions = {
	size: 'md',
	scrollable: true,
	centered: true
};

export const DIALOG_LARGE_CONFIG: NgbModalOptions = {
	size: 'lg',
	scrollable: true,
	centered: true
};