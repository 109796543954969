<div class="share">
	<button id="goup" class="footer__button" title="">
		<svg class="icon icon-up footer__icon" aria-hidden="true" focusable="false"><use href="/assets/cardif/iccrea/wp-content/themes/bnpp-cardif-v2c/dist/icons/sprite.svg#icon-up"></use></svg>	</button>
	<div class="container share__container">
		<p class="share__title">
			Condividi questa pagina		</p>
		<ul class="share__list">
			<li class="share__item">
				<a href="https://www.facebook.com/sharer/sharer.php?u=https://bnpparibascardif.it/&amp;title=BNP%20Paribas%20Cardif%20in%20Italia" target="_blank" class="share__link" title="" rel="noopener">
					<svg class="icon icon-facebook share__icon" aria-hidden="true" focusable="false"><use href="/assets/cardif/iccrea/wp-content/themes/bnpp-cardif-v2c/dist/icons/social.svg#icon-facebook"></use></svg>					<span class="share__label visuallyhidden">Condividi su Facebook (apre una nuova finestra)</span>
				</a>
			</li>
			<li class="share__item">
				<a href="https://twitter.com/share?url=https://bnpparibascardif.it/&amp;title=BNP%20Paribas%20Cardif%20in%20Italia" target="_blank" class="share__link" title="" rel="noopener">
					<svg class="icon icon-twitter share__icon" aria-hidden="true" focusable="false"><use href="/assets/cardif/iccrea/wp-content/themes/bnpp-cardif-v2c/dist/icons/social.svg#icon-twitter"></use></svg>					<span class="share__label visuallyhidden">Condividi su X (apre una nuova finestra)</span>
				</a>
			</li>
			<li class="share__item">
				<a href="https://www.linkedin.com/shareArticle?url=https://bnpparibascardif.it/&amp;title=BNP%20Paribas%20Cardif%20in%20Italia" target="_blank" class="share__link" title="" rel="noopener">
					<svg class="icon icon-linkedin share__icon" aria-hidden="true" focusable="false"><use href="/assets/cardif/iccrea/wp-content/themes/bnpp-cardif-v2c/dist/icons/social.svg#icon-linkedin"></use></svg>					<span class="share__label visuallyhidden">Condividi su LinkedIn (apre una nuova finestra)</span>
				</a>
			</li>
			<li class="share__item">
				<a href="{{urlMail}}" class="share__link" title="">
					<svg class="icon icon-email share__icon" aria-hidden="true" focusable="false"><use href="/assets/cardif/iccrea/wp-content/themes/bnpp-cardif-v2c/dist/icons/social.svg#icon-email"></use></svg>					<span class="share__label visuallyhidden">Condividi per email</span>
				</a>
			</li>
		</ul>
	</div>
</div>