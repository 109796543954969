<!-- Modal Header -->
<app-modal-header></app-modal-header>
<!-- Modal body -->
<div class="modal-body">
    <div class="row px-0 mx-0" *ngIf="isInfoShow">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <dl class="row">
                <dt class="col-sm-3">Tipologia prodotto</dt>
                <dd class="col-sm-9">
                   {{informativeSetView.product_name }}
                </dd>
              
                <dt class="col-sm-3">Intermediario</dt>
                <dd class="col-sm-9">
                    {{informativeSetView.partner_name }}
                </dd>
              
                <dt class="col-sm-3">Cod. convenzione</dt>
                <dd class="col-sm-9">
                    {{informativeSetView.agreement_codes}}
                </dd>

                <dt class="col-sm-3">Cod. prodotto</dt>
                <dd class="col-sm-9">
                    {{informativeSetView.product_codes}}
                </dd>
              </dl>
              <hr>
        </div>
    </div>

    <div class="row px-0 mx-0 my-3">
        <div class="col-lg-12">
            <div id="no-more-tables" class="w-100" [hidden]="isEmpty" [ngClass]="!isEmpty  ? '': 'not_visible'">
                <table class="table table-striped w-100" aria-describedby="table">
                    <thead [hidden]="isSearching">
                        <tr class="bg-white">
                            <th class="pb-2 border-bottom-0" scope="col">Inizio validità</th>
                            <th class="pb-2 border-bottom-0" scope="col">Fine validità</th>
                            <th class="pb-2 border-bottom-0" scope="col">Tipo prodotto</th>
                            <th class="pb-2 border-bottom-0" scope="col"></th>
                        </tr>
                    </thead>
                    <tbody [hidden]="isSearching">
                        <tr class="table-font" *ngFor="let obj of historyView">
                            <td data-title="Inizio validità">
                                <blockquote class=" d-flex justify-content-start align-items-center m-0 ">
                                    {{obj.start_date}}
                                  </blockquote>
                            </td>
                            <td data-title="Fine validità">
                                <blockquote class=" d-flex justify-content-start align-items-center m-0 ">
                                    {{obj.end_date}}
                                  </blockquote>
                            </td>
                            <td data-title="Tipo prodotto">
                                <blockquote class=" d-flex justify-content-start align-items-center m-0 ">
                                    {{obj.product.product_type.description}}
                                  </blockquote>
                            </td>                    
                            <td data-title="">
                                <a rel=noopener href="{{obj.bucket_url}}" target="_blank">doc</a>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr [hidden]="!isSearching">
                            <td colspan="9">
                                <div class="margin-spinner-table">
                                    <app-spinner [visibleSpinner]="isSearching"></app-spinner>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div *ngIf="isEmpty" class="w-100 table-responsive-sm no-data ">
                <app-resultnotfound 
                    *ngIf="resultNotFound"
                    [textBody]="textBody"
                    [styleClass]="styleClass"></app-resultnotfound>
            </div>
        </div>
</div>



<!-- Modal footer -->
<div class="modal-foot">
    <div class="row my-3 text-center">
        <button type="button" class="btn btn-custom-secondary text mx-1 me-3"
            (click)="clickButtonCancel()">Chiudi</button>
    </div>
</div>