<footer id="footer" role="contentinfo">
    <div class="footer-sitemap">
        <div class="wrapper">
            <div class="link-block"> <a href="https://bnpparibascardif.it/chi-siamo" class="category">Chi Siamo</a>
                <ul>
                    <li><a href="https://bnpparibascardif.it/governance">Governance</a></li>
                    <li><a href="https://bnpparibascardif.it/gruppo-bnp-paribas">Gruppo BNP Paribas</a></li>
                    <li><a href="https://bnpparibascardif.it/responsabilita-sociale-d-impresa">Responsabilità sociale
                            d'impresa</a></li>
                    <li><a href="https://bnpparibascardif.it/notizie-e-comunicati">Notizie e Comunicati</a></li>
                    <li><a href="https://bnpparibascardif.it/esg">Informativa sulla sostenibilità</a></li>
                    <li><a href="https://bnpparibascardif.it/politica-di-impegno">Politica di Impegno</a></li>
                    <li><a href="https://bnpparibascardif.it/dicono-di-noi">Dicono di noi</a></li>
                    <li><a href="https://bnpparibascardif.it/glossario">Glossario</a></li>
                </ul>
            </div>
            <div class="link-block"> <a href="https://bnpparibascardif.it/prodotti" class="category">Prodotti</a>
                <ul>
                    <li><a href="https://bnpparibascardif.it/protezione-delle-persone">Protezione delle Persone</a></li>
                    <li><a href="https://bnpparibascardif.it/protezione-dei-beni">Protezione dei Beni</a></li>
                    <li><a href="https://bnpparibascardif.it/protezione-dei-finanziamenti">Protezione dei
                            Finanziamenti</a></li>
                    <li><a href="https://bnpparibascardif.it/risparmio-e-investimento">Risparmio e Investimento</a></li>
                    <li><a href="https://bnpparibascardif.it/previdenza-complementare">Previdenza complementare</a></li>
                    <li><a href="https://valorizzazioni.bnpparibascardif.it/valorizzazioni">Valorizzazioni</a></li>
                    <li><a href="https://setinformativi.bnpparibascardif.it/set-informativi">Set informativi</a></li>
                    <li><a href="https://docs.fairmat.com/bnpparibascardif/products">Rendimenti Passati</a></li>
                    <li><a href="https://bnpparibascardif.it/gestioni-separate-e-fondi-interni">Gestioni Separate e
                            Fondi Interni</a></li>
                    <li><a href="https://bnpparibascardif.it/polizze-poliennali">Polizze Poliennali</a></li>
                    <li><a href="https://bnpparibascardif.it/conflitti-di-interesse">Conflitti di interesse</a></li>
                </ul>
            </div>
            <div class="link-block"> <a href="https://bnpparibascardif.it/area-clienti" class="category">Area
                    Clienti</a>
                <ul>
                    <li><a href="https://bnpparibascardif.it/gestione-del-sinistro">Gestione del Sinistro</a></li>
                    <li><a href="https://bnpparibascardif.it/gestione-del-contratto">Gestione del Contratto</a></li>
                    <li><a href="https://bnpparibascardif.it/pianopensione">PianoPensione</a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="footer-contact">
        <ul>
            <li><a href="https://bnpparibascardif.it/contatti">Contatti</a></li>
            <li><a href="https://bnpparibascardif.it/reclami">Reclami</a></li>
        </ul>
    </div>
    <div class="meta-footer">
        <div class="wrapper"> <a class="back-to-top" href="#"></a>
            <div class="brandflag"> <img alt="logo" src="https://bnpparibascardif.it/image/layout_set_logo?img_id=123153&amp;t=1675148659265">
                <p> L'assicurazione per un mondo che cambia </p>
            </div>
            <ul>
                <li><a href="https://bnpparibascardif.it/cardif-vita-s.p.a.-pi-11552470152">Cardif Vita S.p.A. PI
                        11552470152</a></li>
                <li><a href="https://bnpparibascardif.it/cardif-assurance-vie-pi-08916510152">Cardif Assurance Vie PI
                        08916510152</a></li>
                <li><a href="https://bnpparibascardif.it/cardif-assurances-risques-divers-pi-08916500153">Cardif
                        Assurances Risques Divers PI 08916500153</a></li>
                <li><a href="https://bnpparibascardif.it/privacy">Privacy</a></li>
                <li><a href="https://bnpparibascardif.it/note-legali">Note Legali</a></li>
                <li><a href="https://bnpparibascardif.it/mappa-del-sito">Mappa del sito</a></li>
                <li><a href="https://bnpparibascardif.it/accessibilita">Accessibilità</a></li>
                <li><a href="https://bnpparibascardif.it/conflitti-di-interesse">Conflitti di interesse</a></li>
            </ul>
        </div>
    </div>
</footer>