import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FullComponent } from './components/layout/full/full.component';
import { SetinformativiComponent } from './components/pages/setinformativi/setinformativi.component';
import { ValorizzazioniComponent } from './components/pages/valorizzazioni/valorizzazioni.component';

let routes: Routes = [{
  path: '',
  component: FullComponent,
  children: [
    { path: 'set-informativi', component: SetinformativiComponent, title: 'Set informativi' },
    { path: 'valorizzazioni', component: ValorizzazioniComponent, title: 'Quotazioni' },
  ]
},
{
  path: '**', redirectTo: '/set-informativi', pathMatch: 'full'
}
];

const parsedUrl = new URL(window.location.href);
const baseUrl = parsedUrl.href;
const redirectMapping = [
  {
    title: 'valorizzazioni',
    baseUrls: ['valorizzazioni.bnpparibascardif', 'valorizzazioni.staging'],
    children: [{ path: 'valorizzazioni', component: ValorizzazioniComponent, title: 'Quotazioni' },
    { path: '', redirectTo: '/valorizzazioni', pathMatch: 'full' },
    ],
    redirectTo: '/valorizzazioni',
  },
  {
    title: 'Set informativi',
    baseUrls: ['setinformativi.bnpparibascardif', 'setinformativi.staging'],
    children: [{ path: 'set-informativi', component: SetinformativiComponent, title: 'Set Informativi' },
    { path: '', redirectTo: '/set-informativi', pathMatch: 'full' },
    ],
    redirectTo: '/set-informativi'
  },
  {
    title: 'valorizzazioni',
    baseUrls: ['localhost'],
    children: [
      { path: 'valorizzazioni', component: ValorizzazioniComponent, title: 'Quotazioni' },
      { path: 'set-informativi', component: SetinformativiComponent, title: 'Set Informativi' },
      { path: '', redirectTo: '/valorizzazioni', pathMatch: 'full' },

    ],
    redirectTo: '/valorizzazioni',
  },
  {
    title: 'Set informativi',
    baseUrls: ['cardif-dev'],
    children: [
      { path: 'valorizzazioni', component: ValorizzazioniComponent, title: 'Quotazioni' },
      { path: 'set-informativi', component: SetinformativiComponent, title: 'Set Informativi' },
      { path: '', redirectTo: '/set-informativi', pathMatch: 'full' },
    ],
    redirectTo: '/set-informativi'
  }
]

for (const item of redirectMapping) {
  for (const url of item.baseUrls) {
    if (baseUrl.indexOf(url) > -1) {
      routes = [{
        path: '',
        component: FullComponent,
        children: item.children
      },
      {
        path: '**', redirectTo: item.redirectTo, pathMatch: 'full'
      }
      ] as Routes
    }
  }
}

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
