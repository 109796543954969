export const COMMON_PATH_API = "public/";
export const COMMON_PATH_API_v1 = "v1/";
export const API = {
    informativesetspublicendpoints: {
        old: "informative-sets/{id}/old",
        search: "informative-sets/search",
        preview: "informative-sets/preview/{partnerCode}/{partnerName}/{informativeSetUUID}"
    },
    partner: {
        search: "partners/search",
    },
    funds: {
        search: "funds/search/unit",
    },
    isin: {
        search: "funds/search/isin",
    },
    index: {
        search: "funds/search/index",
    },
    nav: {
        data: "data/{isin}",
    },
    agreement: {
        search: "codes/search",
    },
    productcode: {
        search: "product-codes/search",
        create: "product-codes",
        edit: "product-codes/",
        getById: "product-codes/",
        delete: "product-codes/"
    },
    company: {
        search: "companies/search",
        create: "",
        edit: "companies/",
        getById: "companies/",
        delete: ""
    },
    distributionstatus: {
        search: "distribution-status"
    },
    producttype:{
        search:"product-types/search"
    },
}