import { Component, Input} from '@angular/core';

@Component({
  selector: 'app-resultnotfound',
  templateUrl: './resultnotfound.component.html',
  styleUrls: ['./resultnotfound.component.scss']
})
export class ResultnotfoundComponent {

  @Input() public imageNotResultFound:string = "/assets/images/results_not_found_v0.png";
  @Input() public textBody:string = "Nessun risultato trovato!";
  @Input() public styleClass:string = "result-message";

}
