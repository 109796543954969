import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-headerbanner',
  templateUrl: './headerbanner.component.html'
})
export class HeaderbannerComponent {
  @Input() title!:string;
  @Input() description!:string;
  @Input() headerBannerCustom!:string;

}
