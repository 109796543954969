import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Pagetemplatetable } from 'src/app/classi/pagetemplatetable';
import { DIALOG_LARGE_CONFIG } from 'src/app/config/modal-settings';
import { InformativesetspublicendpointsService } from 'src/app/services/informativesetspublicendpoints.service';
import { InformativesetHistoryModalComponent } from 'src/app/components/templates/informativeset-history-modal/informativeset-history-modal.component';
import { PartnerService } from 'src/app/services/partner.service';
import { AgreementService } from 'src/app/services/agreement.service';
import { ProductcodeService } from 'src/app/services/productcode.service';
import { CompanyService } from 'src/app/services/company.service';
import { DistributionstatusService } from 'src/app/services/distributionstatus.service';
import { ProducttypeService } from 'src/app/services/producttype.service';
import { fromEvent, Subscription } from 'rxjs';
@Component({
  selector: 'app-tablesetinformativi',
  templateUrl: './tablesetinformativi.component.html'
})
export class TablesetinformativiComponent extends Pagetemplatetable implements OnInit {

  @Input() public isActive: boolean = true; //Se è true effettua la ricerca, se è settata a false no
  @Input() public otherFilter: any = null; //Gestione filtri che arrivano dall'esterno
  @Input() public visibleFilterColumns: boolean = false; //impostazione iniziale che rende visibile i filtri

  tableConfig: any = {
    thead: [
      { 'label': 'Intermediario', 'field': "partnerName", 'columnSort': true, 'currentSort': { 'asc': false, 'desc': false } },
      { 'label': 'Polizza / Prodotto', 'field': "ProductCodes", 'columnSort': true, 'currentSort': { 'asc': false, 'desc': false } },
      { 'label': 'Tipologia prodotto', 'field': "productType", 'columnSort': true, 'currentSort': { 'asc': false, 'desc': false } },
      { 'label': 'Inizio validità', 'field': "startValidity", 'columnSort': true, 'currentSort': { 'asc': false, 'desc': false } },
      { 'label': 'Fine validità', 'field': "endValidity", 'columnSort': true, 'currentSort': { 'asc': false, 'desc': false } },
      { 'label': 'Documentazione', 'columnSort': false, 'currentSort': { 'asc': false, 'desc': false } },
      { 'label': 'Compagnia', 'field': "companies", 'columnSort': true, 'currentSort': { 'asc': false, 'desc': false } },
      { 'label': 'Stato', 'field': "distributionStatus", 'columnSort': true, 'currentSort': { 'asc': false, 'desc': false } }
    ]
  };

  placement: string = "top";
  textTooltip_history: string = "Archivio edizioni precedenti";
  textTooltip_preview: string = "Anteprima PDF";
  textTooltip_download: string = "Scarica PDF";
  textTooltip_update: string = "Modifica";
  textTooltip_delete: string = "Elimina";
  textTooltip_restore: string = "Ripristina";
  textTooltip_searchfilter: string = "Filtra";
  textTooltip_clear: string = "Pulisci";
  private keypress$!: Subscription;
  clearFilter!: any;

  constructor(
    public modalService: NgbModal,
    private partnerService: PartnerService,
    private agreementService: AgreementService,
    private productCodeService: ProductcodeService,
    private companyService: CompanyService,
    public distributionStatusService: DistributionstatusService,
    private productTypeService: ProducttypeService,
    private informativesetspublicendpointsService: InformativesetspublicendpointsService
  ) {
    super();
    this.isSearching = false;
  }

  searchInputCurrent!: any;
  clearSingleFilterFlag: any = false;

  searchFilterForm = {
    searchOldValue: '',
    searchFilterField: ''
  };

  searchFilterColumn = {
    partner: "",
    agreementCode: "",
    productCode: "",
    productType: "",
    productName: "",
    company: "",
    distributionStatus: "",
  };
  otherFilterTmp: any = null;

  ngOnInit(): void {
    this.loadTable();
  }

  loadTable() {
    if (this.isActive) {
      this.isSearching = true;
      this.isEmpty = false;
      let filter = this.getFilter();
      let call: any = null;
      call = this.informativesetspublicendpointsService.search(filter)
      call.subscribe({
        next: (response: any) => {
          this.nextLoadTable(response);
        },
        error: (error: HttpErrorResponse) => {
          this.errorLoadTable(error);
        },
        complete: () => {
          this.compliteLoadTable();
        }
      });
    }
  }

  nextLoadTable(response: any) {
    console.log(response);
    if (response != null && typeof response != typeof undefined) {

      this.setEntity(response[this.content]);
      this.numRighe = this.entityList.length;

      if (this.numRighe > 0) {
        if (response.page != null && typeof response.page != typeof undefined) {
          this.setTotalElements(response.page);
        } else {
          this.totElementiPagina = this.numRighe;
        }
        this.calcNumRigheFinoAqui();
        if (this.totElementiPagina == 0) {
          this.isEmpty = true;
          this.resultNotFound = true;
        } else {
          this.isEmpty = false;
          this.resultNotFound = false;
        }
      } else {
        this.totElementiPagina = 0;
        this.isEmpty = true;
        this.resultNotFound = true;
      }
    } else {
      this.setEntity(null);
    }
  }
  errorLoadTable(error: HttpErrorResponse) {
    console.log(error);
    this.setEntity(null);
    this.isEmpty = false;
    this.resultNotFound = false;
  }

  compliteLoadTable() {
    this.isSearching = false;
  }

  cleanFilterObject(obj: any, filter: any) {
    for (let propName in obj) {
      if (obj[propName].trim() != "") {
        filter[propName] = obj[propName];
      }
    }
  }

  getFilter() {
    let filter: any = {};
    if (this.searchInputCurrent != null) {
      if (this.searchInputCurrent.trim() != "") {
        filter["query"] = this.searchInputCurrent;
      }
    } else {
      this.cleanFilterObject(this.searchFilterColumn, filter);
      this.otherFilter = this.otherFilterTmp;
      this.otherFilterTmp = null;
    }
    if (this.numeroPaginaCliccato > 0) {
      filter["page"] = this.numeroPaginaCliccato - 1;
    } else {
      filter["page"] = 0;
    }
    filter["size"] = this.dimPagina;

    //aggiunto sort per la ricerca
    let sort = this.getFilterSort();
    if (sort.length > 0) {
      filter["sort"] = sort;
    }



    return filter;
  }

  pageChange() {
    this.loadTable();
  }

  changePage(event: any) {
    console.log(event);
    if (event == null) {
      event = 0;
    }
    this.numeroPaginaCliccato = event;
    if (
      this.numeroPagina != this.numeroPaginaCliccato
    ) {
      this.loadTable();
    }
  }

  AdvancedSearchToggle() {
    this.visibleFilterColumns = !this.visibleFilterColumns;
  }

  /*  Gestione Search */
  clickSearch() {
    this.clearFilterFn();
    if (this.searchInputCurrent != this.searchFilterForm.searchOldValue) {
      this.searchFilterForm.searchOldValue = this.searchInputCurrent;
    }
    this.loadTable();
  }

  resetSearch() {
    this.searchInputCurrent = "";
    this.searchFilterForm.searchOldValue = "";
  }

  clickButtonSearchFilter() {
    this.otherFilterTmp = this.otherFilter
    this.otherFilter = null;
    this.loadTable();
  }

  clearFilterFn() {
    this.clearSingleFilterFlag = false;

    this.searchFilterColumn = {
      partner: "",
      agreementCode: "",
      productCode: "",
      productName: "",
      productType: "",
      company: "",
      distributionStatus: "",
    };
    this.partnerSelection.clear();
    this.partnerSelection.close();
    this.agreementSelection.clear();
    this.agreementSelection.close();
    this.productSelection.clear();
    this.productSelection.close();
    this.distributionStatusSelection.clear();
    this.distributionStatusSelection.close();
    this.companySelection.clear();
    this.companySelection.close();
  }

  clickButtonClearFilter() {
    this.clearFilterFn();
    this.clickButtonSearchFilter();
  }

  clickButtonSortColumn(index: number) {
    this.sortColumn(index);
    this.loadTable();
  }

  clickButtonHistory(obj: any) {
    //Apertura modale di modifica 
    const modalRef = this.modalService.open(InformativesetHistoryModalComponent, DIALOG_LARGE_CONFIG);
    modalRef.componentInstance.setinformativo = obj;
    modalRef.result.then((result: any) => {
      if (result) {
        if (result != "CLOSE") {
          console.log(result);
        }
      }
    }, (error: any) => {
      console.log(error);
    });
  }

  //Autocomplete Partner - Intermediario
  @ViewChild('partnerSelection') partnerSelection: any = null;
  partner_Keyword = "name";
  partnerSelection_Suggestion: any = [];
  partnerSelection_Selected: any = [];
  partnerSelection_isKeywordLoadingResult: boolean = false;
  partnerSelection_errorMsg: string = "Nessun risultato trovato...";
  loadPartner(event: string) {
    //Caricamento dei dati dei partner
    this.partnerSelection_isKeywordLoadingResult = true;
    let request = this.getFilter_partnerSelection(event);
    this.partnerService.search(request).subscribe((response: any) => {
      this.partnerSelection_Suggestion = [];
      if (response != null && typeof response != typeof undefined) {
        if (response.content != null && typeof response.content != typeof undefined) {
          if (response.content.length > 0) {
            this.partnerSelection_Suggestion = response.content;
          }
        }
      }
      this.partnerSelection_isKeywordLoadingResult = false;
    });
  }
  getFilter_partnerSelection(event: any) {
    let offsetSize = this.partnerSelection_Selected.length;
    let request: any = {
      page: 0,
      size: (100 + offsetSize)
    };
    if (event != "") {
      request["name"] = event;
    }
    return request
  }
  getList_partnerSelection(event: any) {
    console.log(event);
    this.loadPartner(event);
  }
  searchCleared__partnerSelection() {
    this.partnerSelection_Selected = [];
    this.searchFilterColumn.partner = "";
    if (this.clearSingleFilterFlag) {
      this.clickButtonSearchFilter();
    }

  }
  selectEvent__partnerSelection(item: any) {
    this.partnerSelection_Selected.push(item);
    this.searchInputCurrent = null;
    this.searchFilterColumn.partner = item.name;
    this.loadTable();
  }
  onFocused__partnerSelection(e: any) {
    this.clearSingleFilterFlag = true;
    this.getList_partnerSelection("");
  }






  //Autocomplete Agreement - Cod. Convenzione
  @ViewChild('agreementSelection ') agreementSelection: any = null;
  agreementSelection_Keyword = 'temporaryKeyword';
  agreementSelection_Suggestion: any = [];
  agreementSelection_Selected: any = [];
  agreementSelection_isKeywordLoadingResult: boolean = false;
  agreementSelection_errorMsg: string = "Nessun risultato trovato...";
  loadAgreement(event: string) {
    this.agreementSelection_isKeywordLoadingResult = true;
    let request = this.getFilter_agreementSelection(event);
    this.agreementService.search(request).subscribe(response => {
      this.agreementSelection_Suggestion = [];
      if (response != null && typeof response != typeof undefined) {
        if (response.content != null && typeof response.content != typeof undefined) {
          if (response.content.length > 0) {
            this.agreementSelection_Suggestion = response.content;
            this.agreementSelection_Suggestion.map((obj: any) => obj.temporaryKeyword = `${obj.product_code} ${obj.product_name} ${obj.agreement_code}`);
          }
        }
      }
      this.agreementSelection_isKeywordLoadingResult = false;
    });
  }
  getFilter_agreementSelection(event: any) {
    let offsetSize = this.agreementSelection_Selected.length;
    let request: any = {
      page: 0,
      size: (100 + offsetSize)
    };
    if (event != "") {
      request["query"] = event;
    }
    return request
  }
  getList_agreementSelection(event: any) {
    this.loadAgreement(event);
  }
  searchCleared__agreementSelection() {
    this.agreementSelection_Suggestion = [];
    this.searchFilterColumn.agreementCode = "";
    this.searchFilterColumn.productCode = "";
    this.searchFilterColumn.productName = "";
    if (this.clearSingleFilterFlag) {
      this.clickButtonSearchFilter();
    }
  }
  selectEvent__agreementSelection(item: any) {
    this.agreementSelection_Selected.push(item);
    this.searchInputCurrent = null;
    this.searchFilterColumn.agreementCode = item.agreement_code;
    this.searchFilterColumn.productCode = item.product_code;
    this.searchFilterColumn.productName = item.product_name;
    this.loadTable();
  }
  onFocused__agreementSelection(e: any) {
    this.clearSingleFilterFlag = true;
    console.log(e);
    this.getList_agreementSelection("");
  }

  //Autocomplete Companies - Compagnia
  @ViewChild('companySelection ') companySelection: any = null;
  companySelection_Keyword = 'name';
  companySelection_Suggestion: any = [];
  companySelection_Selected: any = [];
  companySelection_isKeywordLoadingResult: boolean = false;
  companySelection_errorMsg: string = "Nessun risultato trovato...";
  loadCompanies(event: string) {
    this.companySelection_isKeywordLoadingResult = true;
    let request = this.getFilter_companySelection(event);
    this.companyService.search(request).subscribe(response => {
      this.companySelection_Suggestion = [];
      if (response != null && typeof response != typeof undefined) {
        if (response.content != null && typeof response.content != typeof undefined) {
          if (response.content.length > 0) {
            //aggiungo elementi nella suggestion
            this.companySelection_Suggestion = response.content;
          }
        }
      }
      this.companySelection_isKeywordLoadingResult = false;
    });
  }
  getFilter_companySelection(event: any) {
    let offsetSize = this.companySelection_Selected.length;
    let request: any = {
      page: 0,
      size: (100 + offsetSize)
    };
    if (event != "") {
      request["name"] = event;
    }
    return request
  }
  getList_companySelection(event: any) {
    this.loadCompanies(event);
  }
  searchCleared__companySelection() {
    this.companySelection_Selected = [];
    this.searchFilterColumn.company = "";
    if (this.clearSingleFilterFlag) {
      this.clickButtonSearchFilter();
    }
  }
  selectEvent__companySelection(item: any) {
    this.companySelection_Selected.push(item);
    this.searchInputCurrent = null;
    this.searchFilterColumn.company = item.name;
    this.loadTable();

  }
  onFocused__companySelection(e: any) {
    this.clearSingleFilterFlag = true;
    console.log(e);
    this.getList_companySelection("");
  }




  //Autocomplete Distribution Status
  @ViewChild('distributionStatusSelection') distributionStatusSelection: any = null;
  distributionStatus_Keyword = 'value';
  distributionStatusSelection_Suggestion: any = [];
  distributionStatusSelection_Selected: any = [];
  distributionStatusSelection_isKeywordLoadingResult: boolean = false;
  distributionStatusSelection_errorMsg: string = "Nessun risultato trovato...";
  loadDistributionStatus() {
    this.distributionStatusSelection_isKeywordLoadingResult = true;
    let request = this.getFilter_distributionStatusSelection();
    //Caricamento dei dati degli stati distribuzione
    this.distributionStatusService.search(request).subscribe(response => {
      this.distributionStatusSelection_Suggestion = [];
      if (response != null && typeof response != typeof undefined) {
        if (response.content != null && typeof response.content != typeof undefined) {
          if (response.content.length > 0) {
            //aggiungo elementi nella suggestion
            this.distributionStatusSelection_Suggestion = response.content;
          }
        }
      }
      this.distributionStatusSelection_isKeywordLoadingResult = false;
    });
  }
  getFilter_distributionStatusSelection() {
    console.log("GETFILTER");

    let offsetSize = this.distributionStatusSelection_Selected.length;
    let request: any = {
      page: 0,
      size: (100 + offsetSize)
    };
    return request
  }
  getList_distributionStatusSelection(event: any) {
    this.searchFilterColumn.distributionStatus = event;
    this.loadDistributionStatus();

  }
  searchCleared__distributionStatusSelection(event: any) {
    console.log("SEARCHCLEARED", this.searchFilterColumn.distributionStatus);
    if (this.searchFilterColumn.distributionStatus != "") {
      this.distributionStatusSelection_Selected = [];
      this.searchFilterColumn.distributionStatus = "";
      if (this.clearSingleFilterFlag) {
        this.clickButtonSearchFilter();
      }
    }
  }
  selectEvent__distributionStatusSelection(item: any) {
    console.log("SELECTEVENT");
    this.distributionStatusSelection_Selected.push(item);
    this.searchInputCurrent = null;
    this.searchFilterColumn.distributionStatus = item.value;
    this.loadTable();
  }

  onFocused__distributionStatusSelection(e: any) {
    this.clearSingleFilterFlag = true;
    this.getList_distributionStatusSelection("");
    if (this.keypress$ && !this.keypress$.closed) {
      this.keypress$.unsubscribe();
    }
    this.keypress$ = fromEvent(e.target, 'keydown').subscribe((e: any) => {
      console.log("fromevent", e);
      e.stopPropagation();
      e.preventDefault();
    })
    //e.target.setAttribute("readonly","true")
  }




  //Autocomplete product
  @ViewChild('productSelection') productSelection: any = null;
  product_Keyword = 'value';
  productSelection_Suggestion: any = [];
  productSelection_Selected: any = [];
  productSelection_isKeywordLoadingResult: boolean = false;
  productSelection_errorMsg: string = "Nessun tipo prodotto trovato...";
  getFilter_productSelection(event: any) {
    let offsetSize = this.productSelection_Selected.length;
    let request: any = {
      page: 0,
      size: (100 + offsetSize)
    };
    if (event != "") {
      request["codes"] = event;
    }
    return request
  }
  getList_productSelection(event: any) {
    this.productSelection_isKeywordLoadingResult = true;
    let request = this.getFilter_productSelection(event);
    this.productTypeService.search(request).subscribe(
      (response: any) => {
        this.productSelection_Suggestion = [];
        if (response != null && typeof response != typeof undefined) {
          if (response.content != null && typeof response.content != typeof undefined) {
            if (response.content.length > 0) {
              this.productSelection_Suggestion = response.content;
            }
          }
        }
        this.productSelection_isKeywordLoadingResult = false;
      });
  }
  searchCleared__productSelection() {
    this.productSelection_Selected = [];
    this.searchFilterColumn.productType = "";
    if (this.clearSingleFilterFlag) {
      this.clickButtonSearchFilter();
    }

  }
  selectEvent__productSelection(item: any) {
    this.productSelection_Selected.push(item);
    this.searchInputCurrent = null;
    this.searchFilterColumn.productType = item.value;
    this.loadTable();
  }
  onFocused__productSelection(e: any) {
    this.clearSingleFilterFlag = true;
    console.log(e);
    this.getList_productSelection("");
  }




}