import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule, NgbPaginationModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FullComponent } from './components/layout/full/full.component';
import { SetinformativiComponent } from './components/pages/setinformativi/setinformativi.component';
import { TablesetinformativiComponent } from './components/pages/setinformativi/tablesetinformativi/tablesetinformativi.component';
import { SpinnerComponent } from './components/templates/spinner/spinner.component';
import { InformativesetHistoryModalComponent } from './components/templates/informativeset-history-modal/informativeset-history-modal.component';
import { ResultnotfoundComponent } from './components/templates/resultnotfound/resultnotfound.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClientModule } from '@angular/common/http';
import { FooterComponent } from './components/layout/footer/footer.component';
import { HeaderbannerComponent } from './components/templates/headerbanner/headerbanner.component';
import { ModalHeaderComponent } from './components/templates/modal-header/modal-header.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InlineStylesCSPModule } from './inline-styles-csp/inline-styles-csp.module';
import { ValorizzazioniComponent } from './components/pages/valorizzazioni/valorizzazioni.component';
import { NgChartsModule } from 'ng2-charts';
import { KeyTolabelPipe } from './pipes/key-tolabel.pipe';

@NgModule({
  declarations: [
    KeyTolabelPipe,
    AppComponent,
    FullComponent,
    SetinformativiComponent,
    TablesetinformativiComponent,
    SpinnerComponent,
    InformativesetHistoryModalComponent,
    ResultnotfoundComponent,
    FooterComponent,
    HeaderComponent,
    HeaderbannerComponent,
    ModalHeaderComponent,
    ValorizzazioniComponent,
  ],
  imports: [
    AutocompleteLibModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgbModule,
    FontAwesomeModule,
    NgbTooltipModule,
    NgbPaginationModule,
    HttpClientModule,
    FormsModule,
    InlineStylesCSPModule,
    NgChartsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
