import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  createParams(objectStoresParams: any) {
    let params = new HttpParams();
    for (let property in objectStoresParams) {
      if (objectStoresParams.hasOwnProperty(property)) {
        if(property == "sort"){
          let array = objectStoresParams[property];
          for (let value of array) {
            params = params.append(property, value);
          }
        }else{
          params = params.append(property, objectStoresParams[property]);
        }
      }
    }
    return params;
  }

  replaceParamPath(path: string, key: string, value: string) {
    return path.replace(key, value);
  }

}
