import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API, COMMON_PATH_API } from '../config/api.conf';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class InformativesetspublicendpointsService {

  constructor(
    private readonly utils: UtilsService, 
    private readonly http: HttpClient,
  ) { }
  pathUrl: string = COMMON_PATH_API;
  baseUrl:string = environment.backend.baseUrl_setInformativi;
  apiBaseUrl:string = this.baseUrl + this.pathUrl;


  old(id:number):Observable<any[]>{
    let path = this.apiBaseUrl+API.informativesetspublicendpoints.old;
    let url = this.utils.replaceParamPath(path,'{id}',id.toString());
    return this.http.get<any[]>(url);
  }
  search(request:any):Observable<any[]>{
    let url = this.apiBaseUrl+API.informativesetspublicendpoints.search;
    let params = this.utils.createParams(request);
    return this.http.get<any>(url,{params:params});
  }
  
}
