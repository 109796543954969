import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API, COMMON_PATH_API } from 'src/app/config/api.conf';
import { IPagedModelAgreementModel } from 'src/app/interface/api-setinformativi.interface';
import { environment } from 'src/environments/environment';
import { UtilsService } from './utils.service';
@Injectable({
  providedIn: 'root'
})
export class AgreementService {

  constructor(
    private readonly http: HttpClient,
    private readonly utils: UtilsService
    ) { }
  pathUrl: string = COMMON_PATH_API;
  baseUrl: string = environment.backend.baseUrl_setInformativi;
  url: string = this.baseUrl + this.pathUrl;

  search(request: any): Observable<IPagedModelAgreementModel> {
    let url = this.url + API.agreement.search;
    let params = this.utils.createParams(request);
    return this.http.get<IPagedModelAgreementModel>(url, { params: params });
  }
}
