import { Component } from '@angular/core';

@Component({
  selector: 'app-setinformativi',
  templateUrl: './setinformativi.component.html'
})
export class SetinformativiComponent {
  titlebanner = "SET INFORMATIVI";
  descriptionbanner = "La documentazione dei nostri prodotti";
  backgroundImageClass = "header-banner-bg-custom";

  visibleFilterColumns:boolean = false;
  toggleVisibleFilterColumns(){
    this.visibleFilterColumns = !this.visibleFilterColumns;
  }

}
