import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InformativesetspublicendpointsService } from 'src/app/services/informativesetspublicendpoints.service';


@Component({
  selector: 'app-informativeset-history-modal',
  templateUrl: './informativeset-history-modal.component.html'
})
export class InformativesetHistoryModalComponent implements OnInit {

  @Input() id:number = -1;
  @Input() setinformativo:any=null;

  isInfoShow:boolean = false;
  isHistoryShow:boolean = true;



  informativeSetView:any ={
    id: -1,
    product_name:"",
    partner_name:"",
    agreement_codes:"",
    product_codes:""
  }

  historyView:any = [];


  isEmpty:boolean = false;
  isSearching:boolean = true;
  resultNotFound: boolean = true;
  textBody:string = "Nessun set informativo trovato nello storico!";
  styleClass:string = "";

  constructor(
    public activeModal: NgbActiveModal,
    private informativesetspublicendpointsService:InformativesetspublicendpointsService
  ) { }

  ngOnInit(): void {
    if(this.id!= -1){
      this.isInfoShow = true;
      this.loadHistory(this.id);
    }else if(this.setinformativo != null && typeof this.setinformativo != typeof undefined){
      this.isInfoShow = true;
      this.loadDataByObj(this.setinformativo);
    }  
  }

  loadDataByObj(obj:any){
    this.informativeSetView.id = obj.id;
    this.informativeSetView.product_name = obj.product_name;
    this.informativeSetView.partner_name = obj.partner_name;
    this.informativeSetView.agreement_codes = obj.agreement_codes;
    this.informativeSetView.product_codes = obj.product_codes;
    this.loadHistory(obj.id);
  }

  loadHistory(id:number){
    if(id!= -1){
      this.informativesetspublicendpointsService.old(id).subscribe({
        next: (response:any[]) => {
          console.log(response);
          if(response){
            //Salvato
            this.isEmpty = false;
            this.historyView = response;
            if(this.historyView.length > 0){
              this.isSearching = false;
            }else{
              this.isEmpty = true;
              this.isSearching = false;
            }
          }
        },
        error: (e: HttpErrorResponse) => {
          console.error(e);
 
        },
        complete: () => {
          console.log("complete");

        } 
      });
    }
    
  }

  clickButtonCancel() {
    this.activeModal.close('CLOSE');
  }
}
