import { Injectable } from '@angular/core';
import { API, COMMON_PATH_API_v1 } from '../config/api.conf';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { IPagedModelFundModel, IPagedModelIndexModel, IPagedModelIsinModel } from '../interface/api-setinformativi.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class ValorizzazioniService {

  constructor(
    private http: HttpClient,
    private utils: UtilsService
  ) { }
  pathUrl: string = COMMON_PATH_API_v1;
  baseUrl: string = environment.backend.baseUrl_valorizzazioni;
  url: string = this.baseUrl + this.pathUrl;

  searchUnit(request: any): Observable<IPagedModelFundModel> {
    let params = this.utils.createParams(request);
    let url = this.url + API.funds.search;
    return this.http.get<IPagedModelFundModel>(url, { params: params });
  }

  searchIndex(request: any): Observable<IPagedModelIndexModel> {
    let params = this.utils.createParams(request);
    let url = this.url + API.index.search;
    return this.http.get<IPagedModelIndexModel>(url, { params: params });
  }

  searchIsin(request: any): Observable<IPagedModelIsinModel> {
    let params = this.utils.createParams(request);
    let url = this.url + API.isin.search;
    return this.http.get<IPagedModelIsinModel>(url, { params: params });
  }

  getByIsin(isin: string, interval: string): Observable<any> {
    let url = this.url + API.nav.data
    let urlWithParams = this.utils.replaceParamPath(url, '{isin}', isin);
    let params = new HttpParams();
    params = params.set('interval', interval);
    return this.http.get<any>(urlWithParams, {params: params});
  }

}
