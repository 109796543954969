<header id="banner" role="banner">
    <div class="topbar">
        <div class="wrapper">
            <ul>
                <li><a href="https://bnpparibascardif.it/contatti">Contatti</a></li>
                <li><a
                        href="https://area-personale.bnpparibascardif.it/content/mycardif/it/on-boarding.html">MyCardif</a>
                </li>
                <li><a href="https://pass-portal.bnpparibascardif.it/portal/#!/login">Area Partner</a></li>
            </ul>
        </div>
    </div>
    <div class="menubar">
        <div class="wrapper">
            <ul class="features">
                <li class="navigation burger"> <a href="#"></a>
                    <ul class="submenu">
                        <li><a href="https://bnpparibascardif.it/chi-siamo">Chi Siamo</a></li>
                        <li><a href="https://bnpparibascardif.it/prodotti">Prodotti</a></li>
                        <li><a href="https://bnpparibascardif.it/area-clienti">Area Clienti</a></li>
                        <li class="search-mobile">
                            <form
                                action="https://bnpparibascardif.it/set-informativi?p_p_auth=rAwBAwpy&amp;p_p_id=77&amp;p_p_lifecycle=0&amp;p_p_state=maximized&amp;p_p_mode=view&amp;_77_struts_action=%2Fjournal_content_search%2Fsearch&amp;_77_showListed=true"
                                class="form " id="fm" method="post" name="fm"> <input name="formDate" type="hidden"
                                    value="1675332238055">
                                <div class="control-group control-group-inline input-text-wrapper"> <input
                                        class="field lfr-search-keywords" id="_77_keywords_mlbh" name="_77_keywords"
                                        placeholder="Ricerca..." title="Cerca contenuto" type="text" value="" size="30">
                                </div>
                                <div class="control-group control-group-inline input-text-wrapper"> <input alt="Ricerca"
                                        class="field lfr-search-button" id="search" name="search" title="Ricerca"
                                        type="image"
                                        src="https://bnpparibascardif.it/bpc-csf-ct-generic-theme/images/common/search.png">
                                </div>
                            </form> <!-- Hides google search, waiting for licensing decisions to restore or remove -->
                        </li>
                    </ul>
                </li>
            </ul>
            <div class="brandflag"> <a class="logo" href="https://bnpparibascardif.it"> <img alt="logo"
                        src="/assets/images/layout_set_logo.png"> </a>
                <p> L'assicurazione per un mondo che cambia</p>
            </div>
        </div>
    </div>
    <div class="navbar-wrapper burger">
        <nav class="sort-pages modify-pages navbar site-navigation" id="navigation" role="navigation">
            <div class="navbar-inner">
                <ul aria-label="Pagine Sito" class="nav" role="menubar">
                    <li class="lfr-nav-item" id="layout_1" role="presentation"
                        itemtype="https://schema.org/SiteNavigationElement" itemscope=""> <a aria-labelledby="layout_1"
                            class="" href="https://bnpparibascardif.it/home" role="menuitem"> <span> Home</span> </a>
                    </li>
                    <li class="lfr-nav-item dropdown" id="layout_3" role="presentation"
                        itemtype="https://schema.org/SiteNavigationElement" itemscope=""> <a aria-labelledby="layout_3"
                            aria-haspopup="true" class="dropdown-toggle" href="https://bnpparibascardif.it/chi-siamo"
                            role="menuitem"> <span> Chi Siamo</span> </a>
                        <ul class="dropdown-menu child-menu" role="menu">
                            <li class="" id="layout_34" role="presentation"> <a aria-labelledby="layout_34"
                                    href="https://bnpparibascardif.it/governance" role="menuitem">Governance</a> </li>
                            <li class="" id="layout_35" role="presentation"> <a aria-labelledby="layout_35"
                                    href="https://bnpparibascardif.it/gruppo-bnp-paribas" role="menuitem">Gruppo BNP
                                    Paribas</a> </li>
                            <li class="" id="layout_36" role="presentation"> <a aria-labelledby="layout_36"
                                    href="https://bnpparibascardif.it/responsabilita-sociale-d-impresa"
                                    role="menuitem">Responsabilità sociale d'impresa</a> </li>
                            <li class="" id="layout_37" role="presentation"> <a aria-labelledby="layout_37"
                                    href="https://bnpparibascardif.it/notizie-e-comunicati" role="menuitem">Notizie e
                                    Comunicati</a> </li>
                            <li class="" id="layout_138" role="presentation"> <a aria-labelledby="layout_138"
                                    href="https://bnpparibascardif.it/esg" role="menuitem">Informativa sulla
                                    sostenibilità</a> </li>
                            <li class="" id="layout_139" role="presentation"> <a aria-labelledby="layout_139"
                                    href="https://bnpparibascardif.it/politica-di-impegno" role="menuitem">Politica di
                                    Impegno</a> </li>
                            <li class="" id="layout_168" role="presentation"> <a aria-labelledby="layout_168"
                                    href="https://bnpparibascardif.it/dicono-di-noi" role="menuitem">Dicono di noi</a>
                            </li>
                            <li class="" id="layout_169" role="presentation"> <a aria-labelledby="layout_169"
                                    href="https://bnpparibascardif.it/glossario" role="menuitem">Glossario</a> </li>
                        </ul>
                    </li>
                    <li class="lfr-nav-item selected active dropdown" id="layout_4" aria-selected="true"
                        role="presentation" itemtype="https://schema.org/SiteNavigationElement" itemscope=""> <a
                            aria-labelledby="layout_4" aria-haspopup="true" class="dropdown-toggle"
                            href="https://bnpparibascardif.it/prodotti" role="menuitem"> <span> Prodotti</span> </a>
                        <ul class="dropdown-menu child-menu" role="menu">
                            <li class="" id="layout_18" role="presentation"> <a aria-labelledby="layout_18"
                                    href="https://bnpparibascardif.it/protezione-delle-persone"
                                    role="menuitem">Protezione delle Persone</a> </li>
                            <li class="" id="layout_19" role="presentation"> <a aria-labelledby="layout_19"
                                    href="https://bnpparibascardif.it/protezione-dei-beni" role="menuitem">Protezione
                                    dei Beni</a> </li>
                            <li class="" id="layout_20" role="presentation"> <a aria-labelledby="layout_20"
                                    href="https://bnpparibascardif.it/protezione-dei-finanziamenti"
                                    role="menuitem">Protezione dei Finanziamenti</a> </li>
                            <li class="" id="layout_21" role="presentation"> <a aria-labelledby="layout_21"
                                    href="https://bnpparibascardif.it/risparmio-e-investimento"
                                    role="menuitem">Risparmio e Investimento</a> </li>
                            <li class="" id="layout_22" role="presentation"> <a aria-labelledby="layout_22"
                                    href="https://bnpparibascardif.it/previdenza-complementare"
                                    role="menuitem">Previdenza complementare</a> </li>
                            <li class="" id="layout_23" role="presentation"> <a aria-labelledby="layout_23"
                                    href="https://valorizzazioni.bnpparibascardif.it/valorizzazioni" role="menuitem">Valorizzazioni</a>
                            </li>
                            <li class="active" id="layout_24" aria-selected="true" role="presentation"> <a
                                    aria-labelledby="layout_24" href="https://setinformativi.bnpparibascardif.it/set-informativi"
                                    role="menuitem">Set informativi</a> </li>
                            <li class="" id="layout_175" role="presentation"> <a aria-labelledby="layout_175"
                                    href="https://docs.fairmat.com/bnpparibascardif/products" role="menuitem">Rendimenti
                                    Passati</a> </li>
                            <li class="" id="layout_25" role="presentation"> <a aria-labelledby="layout_25"
                                    href="https://bnpparibascardif.it/gestioni-separate-e-fondi-interni"
                                    role="menuitem">Gestioni Separate e Fondi Interni</a> </li>
                            <li class="" id="layout_27" role="presentation"> <a aria-labelledby="layout_27"
                                    href="https://bnpparibascardif.it/polizze-poliennali" role="menuitem">Polizze
                                    Poliennali</a> </li>
                            <li class="" id="layout_100" role="presentation"> <a aria-labelledby="layout_100"
                                    href="https://bnpparibascardif.it/conflitti-di-interesse" role="menuitem">Conflitti
                                    di interesse</a> </li>
                        </ul>
                    </li>
                    <li class="lfr-nav-item dropdown" id="layout_5" role="presentation"
                        itemtype="https://schema.org/SiteNavigationElement" itemscope=""> <a aria-labelledby="layout_5"
                            aria-haspopup="true" class="dropdown-toggle" href="https://bnpparibascardif.it/area-clienti"
                            role="menuitem"> <span> Area Clienti</span> </a>
                        <ul class="dropdown-menu child-menu" role="menu">
                            <li class="" id="layout_28" role="presentation"> <a aria-labelledby="layout_28"
                                    href="https://bnpparibascardif.it/gestione-del-sinistro" role="menuitem">Gestione
                                    del Sinistro</a> 
                            </li>
                            <li class="" id="layout_29" role="presentation"> <a aria-labelledby="layout_29"
                                    href="https://bnpparibascardif.it/gestione-del-contratto" role="menuitem">Gestione
                                    del Contratto</a> 
                            </li>
                            <li class="" id="layout_30" role="presentation"> <a aria-labelledby="layout_30"
                                    href="https://bnpparibascardif.it/pianopensione" role="menuitem">PianoPensione</a>
                            </li>
                        </ul>
                    </li>
                    <li class="lfr-nav-item dropdown languages" role="presentation itemtype="> 
                        <a data-toggle=" dropdown" class="dropdown-toggle" href="#">Lingue</a>
                        <ul class="dropdown-menu child-menu" role="menu">
                            <li> 
                                <a href="https://bnpparibascardif.it/set-informativi?p_p_id=82&amp;p_p_lifecycle=1&amp;p_p_state=normal&amp;p_p_mode=view&amp;_82_struts_action=%2Flanguage%2Fview&amp;_82_redirect=%2Fweb%2Fitaly%2Fset-informativi&amp;_82_languageId=it_IT" lang="it-IT"> Italian (Italy) </a> 
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </nav>
        <script>Liferay.Data.NAV_LIST_SELECTOR = ".navbar-inner > ul";</script>
    </div>
    <div class="horizontal-menu burger">
        <ul>
            <li class="mobile-toggle">&nbsp;</li>
            <li> <a href="https://bnpparibascardif.it/web/italy/protezione-delle-persone">Protezione delle Persone</a>
            </li>
            <li> <a href="https://bnpparibascardif.it/web/italy/protezione-dei-beni">Protezione dei Beni</a></li>
            <li> <a href="https://bnpparibascardif.it/web/italy/protezione-dei-finanziamenti">Protezione dei
                    Finanziamenti</a></li>
            <li> <a href="https://bnpparibascardif.it/web/italy/risparmio-e-investimento">Risparmio e Investimento</a>
            </li>
            <li> <a href="https://bnpparibascardif.it/web/italy/previdenza-complementare">Previdenza complementare</a>
            </li>
            <li [ngClass]="currentUrl == '/valorizzazioni' ? 'current' : ''"> <a href="{{baseUrlValorizzazioni}}">Valorizzazioni</a></li>
            <li [ngClass]="currentUrl == '/set-informativi' ? 'current' : ''"> <a href="{{baseUrlSetinformativi}}">Set informativi</a>
            </li>
            <li> <a href="https://bnpparibascardif.it/web/italy/rendimentipassati">Rendimenti Passati</a></li>
            <li> <a href="https://bnpparibascardif.it/web/italy/gestioni-separate-e-fondi-interni">Gestioni Separate e
                    Fondi Interni</a></li>
            <li> <a href="https://bnpparibascardif.it/web/italy/polizze-poliennali">Polizze Poliennali</a></li>
            <li> <a href="https://bnpparibascardif.it/web/italy/conflitti-di-interesse">Conflitti di interesse</a></li>
        </ul>
    </div>
</header>