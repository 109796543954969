import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html'
})
export class ModalHeaderComponent {
  titleModal:string ="Storico della documentazione";
  
  constructor(
    private activeModal: NgbActiveModal
  ) { }

  clickButtonCancel() {
    this.activeModal.close('CLOSE');
  }

}
