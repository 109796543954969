<div class="col-12">
    <div id="box-search-setinformativi" class="box-search">
        <input class="form-control searchInput" type="search" name="searchInputCurrent" placeholder="Cerca..."
            [(ngModel)]="searchInputCurrent" (search)="clickSearch()" aria-label="Search">
        <button type="submit" class="btn-search" (click)="clickSearch()">
            <img class="search-img" src="/assets/images/magnifying-glass.svg" alt="">
        </button>
    </div>
</div>

<div class="advanced-search-button clear-filter" #clearFilter [hidden]="!visibleFilterColumns">
    <span class="txt-remove" (click)="clickButtonClearFilter()"> Pulisci filtri </span>
</div>
<div class="w-100">
    <table class="table table-striped" style="width: 100%;" aria-describedby="table">
        <thead>
            <tr class="filters bg-white" [hidden]="!visibleFilterColumns">
                <th class="px-1  pb-2 border-bottom-0 filter-column" scope="col">
                    <ng-autocomplete #partnerSelection placeholder="Intermediario" [focusFirst]="true" [data]="partnerSelection_Suggestion"
                        [searchKeyword]="partner_Keyword" (selected)='selectEvent__partnerSelection($event)'
                        (inputChanged)='getList_partnerSelection($event)'
                        (inputFocused)='onFocused__partnerSelection($event)'
                        (inputCleared)="searchCleared__partnerSelection()"
                        [itemTemplate]="itemTemplate_partnerSelection"
                        [notFoundTemplate]="notFoundTemplate_partnerSelection" [debounceTime]="600"
                        [isLoading]="partnerSelection_isKeywordLoadingResult" [minQueryLength]="1">
                    </ng-autocomplete>
                    <ng-template #itemTemplate_partnerSelection let-item>
                        <div class="col-12">
                            <div class="mb-1 mx-3 me-0">
                                <small [innerHTML]="item.name"></small>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template #notFoundTemplate_partnerSelection let-notFound>
                        {{partnerSelection_errorMsg}}
                    </ng-template>
                </th>
                <th class="px-1  pb-2 border-bottom-0 filter-column" scope="col">
                    <ng-autocomplete #agreementSelection placeholder="Polizza / Prodotto"
                        [data]="agreementSelection_Suggestion" [focusFirst]="true" [searchKeyword]="agreementSelection_Keyword"
                        (selected)='selectEvent__agreementSelection($event)'
                        (inputChanged)='getList_agreementSelection($event)'
                        (inputFocused)='onFocused__agreementSelection($event)'
                        (inputCleared)="searchCleared__agreementSelection()"
                        [itemTemplate]="itemTemplate_agreementSelection"
                        [notFoundTemplate]="notFoundTemplate_agreementSelection" [debounceTime]="600"
                        [isLoading]="agreementSelection_isKeywordLoadingResult" [minQueryLength]="2">
                    </ng-autocomplete>
                    <ng-template #itemTemplate_agreementSelection let-item>
                        <div class="col-12">
                            <div class="mb-1 mx-3 me-0">
                                <small
                                    [innerHTML]="item.product_code + (item.agreement_code.length > 0 ? ' - ': '') + item.agreement_code + (item.product_name.length > 0 ? ' - ': '') + item.product_name"></small>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template #notFoundTemplate_agreementSelection let-notFound>
                        {{agreementSelection_errorMsg}}
                    </ng-template>
                </th>
                <th class="px-1  pb-2 border-bottom-0 filter-column" scope="col">
                    <ng-autocomplete #productSelection placeholder="Tipologia prodotto" [data]="productSelection_Suggestion" [focusFirst]="true"
                        [searchKeyword]="product_Keyword" (selected)='selectEvent__productSelection($event)'
                        (inputChanged)='getList_productSelection($event)'
                        (inputFocused)='onFocused__productSelection($event)'
                        (inputCleared)="searchCleared__productSelection()"
                        [itemTemplate]="itemTemplate_productSelection"
                        [notFoundTemplate]="notFoundTemplate_productSelection" [debounceTime]="400"
                        [isLoading]="productSelection_isKeywordLoadingResult" [minQueryLength]="1">
                    </ng-autocomplete>
                    <ng-template #itemTemplate_productSelection let-item>
                        <div class="col-12">
                            <div class="mb-1 mx-3 me-0">
                                <small [innerHTML]="item.value"></small>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template #notFoundTemplate_productSelection let-notFound>
                        {{productSelection_errorMsg}}
                    </ng-template>






                </th>
                <th class="pb-2 border-bottom-0" scope="col">Inizio validità</th>
                <th class="pb-2 border-bottom-0" scope="col">Fine validità</th>
                <th class="pb-2 border-bottom-0" scope="col">Documentazione</th>
                <th class="px-1 pb-2 border-bottom-0 filter-column" scope="col">
                    <ng-autocomplete #companySelection placeholder="Compagnia" [data]="companySelection_Suggestion" [focusFirst]="true" 
                        [searchKeyword]="companySelection_Keyword" (selected)='selectEvent__companySelection($event)'
                        (inputChanged)='getList_companySelection($event)'
                        (inputFocused)='onFocused__companySelection($event)'
                        (inputCleared)="searchCleared__companySelection()"
                        [itemTemplate]="itemTemplate_companySelection"
                        [notFoundTemplate]="notFoundTemplate_companySelection" [debounceTime]="600"
                        [isLoading]="companySelection_isKeywordLoadingResult" [minQueryLength]="2">
                    </ng-autocomplete>
                    <ng-template #itemTemplate_companySelection let-item>
                        <div class="col-12">
                            <div class="mb-1 mx-3 me-0">
                                <small [innerHTML]="item.name"></small>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template #notFoundTemplate_companySelection let-notFound>
                        {{companySelection_errorMsg}}
                    </ng-template>
                </th>
                <th class="px-1 pb-2 border-bottom-0 filter-column" scope="col">
                    <ng-autocomplete #distributionStatusSelection placeholder="Stato"
                        [data]="distributionStatusSelection_Suggestion" [searchKeyword]="distributionStatus_Keyword"
                        (selected)='selectEvent__distributionStatusSelection($event)'
                        (inputChanged)='getList_distributionStatusSelection($event)'
                        (inputFocused)='onFocused__distributionStatusSelection($event)'
                        (inputCleared)="searchCleared__distributionStatusSelection($event)"
                        [itemTemplate]="itemTemplate_distributionStatusSelection"
                        [notFoundTemplate]="notFoundTemplate_distributionStatusSelection" [debounceTime]="600"
                        [isLoading]="distributionStatusSelection_isKeywordLoadingResult" [minQueryLength]="1">
                    </ng-autocomplete>
                    <ng-template #itemTemplate_distributionStatusSelection let-item>
                        <div class="col-12">
                            <div class="mx-3">
                                <span [innerHTML]="item.value"></span>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template #notFoundTemplate_distributionStatusSelection let-notFound>
                        {{distributionStatusSelection_errorMsg}}
                    </ng-template>
                </th>
            </tr>
            <tr [hidden]="visibleFilterColumns">
                <th class="pb-4" scope="col" *ngFor="let obj of tableConfig.thead; index as i">
                    <div *ngIf="obj.columnSort" [ngClass]="getClassSortByObj(obj)" (click)="clickButtonSortColumn(i)"
                        class="sortcolumn clickable">
                        {{obj.label}}
                    </div>
                    <div *ngIf="!obj.columnSort" class="sortcolumn ">
                        {{obj.label}}
                    </div>

                </th>
            </tr>
        </thead>
        <tbody [hidden]="isSearching">
            <tr class="table-font" *ngFor="let obj of entityList">
                <td>{{obj.partner_name}}</td>
                <td>{{(obj.agreement_codes != null ? obj.agreement_codes : '') + ((obj.product_codes != null && obj.agreement_codes != null) && (obj.product_codes != "" && obj.agreement_codes != "") ? ' - ' :
                    '') + (obj.product_codes != null ? obj.product_codes : '')}} <br> {{obj.product_name}}</td> 
                <td>{{obj.product_type }}</td>
                <td>
                    <span class="nowrap">
                        {{obj.sale_start }}
                    </span>

                </td>
                <td>
                    <span class="nowrap">
                        {{obj.sale_end }}
                    </span>
                </td>
                <td>
                    <div class="d-flex">
                        <a class="btn btn-circle me-3" [href]="obj.downloadURL" target="_blank">
                            <em class="fa-solid fa-file-pdf pdf clickable" [ngbTooltip]="textTooltip_preview" placement="{{placement}}" aria-label="Anteprima PDF"></em>
                        </a>
                        <a class="btn btn-circle me-3" *ngIf="obj.has_archive" (click)="clickButtonHistory(obj)">
                            <em class="fa-solid  fa-clock-rotate-left green clickable " [ngbTooltip]="textTooltip_history" placement="{{placement}}" aria-label="Storico"></em>
                        </a>
                    </div>
                </td>
                <td>{{obj.companies}}</td>
                <td>{{obj.publishing_status}}</td>
            </tr>
        </tbody>
        <tfoot>
            <tr [hidden]="!isSearching">
                <td colspan="9">
                    <app-spinner [visibleSpinner]="isSearching"></app-spinner>
                </td>
            </tr>
            <tr [hidden]="isSearching">
                <td colspan="10" [hidden]="isEmpty">

                    <div class="col d-flex align-items-center">
                        <div class="table-box-info" *ngIf="isVisibleTableBoxInfo">
                            <span>
                                {{numRigheFinoAqui}}/{{totElementiPagina}} Elementi visualizzati
                            </span>
                        </div>
                        <div class="pagination-position">
                            <ngb-pagination [boundaryLinks]="true" (pageChange)="changePage($event)"
                                [collectionSize]="totElementiPagina" [maxSize]="maxSize" [pageSize]="dimPagina"
                                [(page)]="numeroPagina">
                            </ngb-pagination>
                        </div>
                    </div>

                </td>
                <td colspan="10" [hidden]="!isEmpty">
                    <app-resultnotfound *ngIf="resultNotFound"></app-resultnotfound>
                </td>

            </tr>
        </tfoot>
    </table>
</div>