export const LINK = {
    chisiamo:{label:"Chi siamo",href:"https://bnpparibascardif.it/chi-siamo/"},
    Contattaci:{label:"Contattaci",href:"https://bnpparibascardif.it/contattaci/"},
    MyCardif :{label:"MyCardif",href:"https://mycardif.it"},
    Protezione :{label:"Protezione",href:" https://bnpparibascardif.it/protezione/"},
    RisparmioeInvestimento :{label:"Risparmio e Investimento",href:" https://bnpparibascardif.it/risparmio-e-investimento/"},
    Previdenza :{label:"Previdenza",href:"https://bnpparibascardif.it/previdenza/"},
    FAQ :{label:"FAQ",href:"https://bnpparibascardif.it/faq/"},
    RECLAMI :{label:"RECLAMI",href:"https://bnpparibascardif.it/reclami/"},
    CONTATTACI:{label:"CONTATTACI",href:"https://bnpparibascardif.it/contattaci/"},
    DatiSocietari :{label:"Dati Societari",href:"https://bnpparibascardif.it/dati-societari/"},
    NoteLegali :{label:"Note Legali",href:"https://bnpparibascardif.it/note-legali/"},
    Privacy :{label:"Privacy",href:"https://bnpparibascardif.it/privacy/"},
    Accessibilita :{label:"Accessibilità",href:"https://bnpparibascardif.it/accessibilita/"},
    conflittidinteresse :{label:"Conflitti d’interesse",href:"https://bnpparibascardif.it/conflitti-dinteresse/"},
    Whistleblowing :{label:"Whistleblowing",href:"https://bnpparibascardif.it/whistleblowing/"},
    sostenibilita :{label:"Sostenibilità",href:"https://bnpparibascardif.it/chi-siamo/sostenibilita"},
    lavoraconnoi :{label:"Lavora con noi",href:"https://bnpparibascardif.it/lavora-con-noi/"},
    NotizieeComunicati :{label:"Notizie e Comunicati",href:"https://bnpparibascardif.it/notizie-e-comunicati/"},
    AreaPartner :{label:"Area Partner",href:"https://pass-portal.bnpparibascardif.it/portal/#!/login"},
    logo :{label:"logo",href:"https://bnpparibascardif.it/"},
    Scoprichisiamo : {label:"Scopri chi siamo",href:"https://bnpparibascardif.it/chi-siamo/"}
}