<div class="hero hero__home">
    <div class="container">
        <div class="hero__text">
            <h1 class="hero__title">{{hero__title}}</h1>
            <p class="hero__baseline">{{hero__baseline}} </p>
        </div>
    </div>
    <noscript>
        <img src="{{hero__picture_url}}?w=700&h=658&crop=1"
            class="lazyload hero__img" alt="" />
    </noscript>
    <picture>
        <source
            data-srcset="{{hero__picture_url}}?w=375&amp;h=375&amp;crop=1, {{hero__picture_url}}?w=700&amp;h=658&amp;crop=1 2x"
            media="(max-width: 768px)"
            srcset="{{hero__picture_url}}?w=375&amp;h=375&amp;crop=1, {{hero__picture_url}}?w=700&amp;h=658&amp;crop=1 2x">
        <source
            data-srcset="{{hero__picture_url}}?w=700&amp;h=658&amp;crop=1, {{hero__picture_url}}?w=700&amp;h=658&amp;crop=1 2x"
            srcset="{{hero__picture_url}}?w=700&amp;h=658&amp;crop=1, {{hero__picture_url}}?w=700&amp;h=658&amp;crop=1 2x">
        <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
            class="hero__img lazyloaded" alt="">
    </picture>
</div>