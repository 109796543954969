<!--Header-->
<a href="#main-content" id="skip-to-content">Salta al contenuto</a>
<div class="container-fluid" id="wrapper">
    <app-header></app-header>
    <div id="content">
        <div class="row-fluid"> 
            <div class="span12"> 
                <router-outlet></router-outlet>
            </div>
        </div>      
    </div>
    <app-footer></app-footer>
</div>
<!--footer-->