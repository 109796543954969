<div class="footer__wrapper">
    <div class="container footer__container-top">
        <div class="footer__left">
            <p style="text-align: center">Dal 1989 sviluppiamo e distribuiamo soluzioni assicurative nel mondo del Risparmio e della Protezione. <a
                    href="{{LINK.Scoprichisiamo.href}}">{{LINK.Scoprichisiamo.label}}</a></p>
            <p style="text-align: center"><img loading="lazy" class="size-medium wp-image-86"
                    src="/assets/cardif/images/torre-diamante.webp?w=300"
                    alt="" width="300" height="171"></p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </div>
        <div class="footer__right">
            <nav class="footer__nav" role="navigation" aria-label="Navigazione a piè di pagina">
                <div class="footer__column">
                    <p class="footer__title">BNP Paribas Cardif Italia</p>
                    <ul class="footer__menu menu">
                        <li class="menu-item">
                            <a href="{{LINK.DatiSocietari.href}}">
                               {{LINK.DatiSocietari.label}}</a>
                        </li>
                        <li class="menu-item">
                            <a href="{{LINK.NoteLegali.href}}">
                                {{LINK.NoteLegali.label}}</a>
                        </li>
                        <li class="menu-item">
                            <a href="{{LINK.Privacy.href}}">
                                {{LINK.Privacy.label}}</a>
                        </li>
                        <li class="menu-item">
                            <a href="{{LINK.Accessibilita.href}}">
                                {{LINK.Accessibilita.label}} </a>
                        </li>
                        <li class="menu-item">
                            <a href="{{LINK.conflittidinteresse.href}}">
                                {{LINK.conflittidinteresse.label}}  </a>
                        </li>
                        <li class="menu-item">
                            <a href="{{LINK.Whistleblowing.href}}">
                                {{LINK.Whistleblowing.label}} </a>
                        </li>

                    </ul>
                </div>
                <div class="footer__column">
                    <p class="footer__title">Link Utili</p>
                    <ul class="footer__menu menu">
                        <li class="menu-item">
                            <a href="{{LINK.sostenibilita.href}}">
                                {{LINK.sostenibilita.label}} </a>
                        </li>
                        <li class="menu-item">
                            <a href="{{LINK.lavoraconnoi.href}}">
                                {{LINK.lavoraconnoi.label}} </a>
                        </li>
                        <li class="menu-item">
                            <a href="{{LINK.NotizieeComunicati.href}}">
                                {{LINK.NotizieeComunicati.label}} </a>
                        </li>
                        <li class="menu-item">
                            <a href="{{LINK.AreaPartner.href}}"
                                title="Area Partner - Nuova finestra" target="_blank"
                                rel="noopener noreferrer nofollow">
                                {{LINK.AreaPartner.label}} </a>
                        </li>
                    </ul>
                </div>

                <div class="footer__column">
                    <p class="footer__title">Seguici</p>
                    <ul class="footer__menu menu">
                        <li class="menu-item">
                            <a href="https://www.linkedin.com/company/bnp-paribas-cardif/posts/?feedView=all"
                                title=" - Nuova finestra" target="_blank" rel="noopener noreferrer nofollow">
                                <svg class="icon icon-linkedin social-icon" aria-hidden="true" focusable="false">
                                    <use
                                        href="/assets/cardif/iccrea/wp-content/themes/bnpp-cardif-v2c/dist/icons/social.svg#icon-linkedin">
                                    </use>
                                </svg> <span class="social__label"></span>
                            </a>
                        </li>
                        <li class="menu-item">
                            <a href="https://www.facebook.com/bnpparibascardif" title=" - Nuova finestra"
                                target="_blank" rel="noopener noreferrer nofollow">
                                <svg class="icon icon-facebook social-icon" aria-hidden="true" focusable="false">
                                    <use
                                        href="/assets/cardif/iccrea/wp-content/themes/bnpp-cardif-v2c/dist/icons/social.svg#icon-facebook">
                                    </use>
                                </svg> <span class="social__label"></span>
                            </a>
                        </li>
                        <li class="menu-item">
                            <a href="https://twitter.com/bnppcardifit?ref_src=twsrc%5Etfw" title=" - Nuova finestra"
                                target="_blank" rel="noopener noreferrer nofollow">
                                <svg class="icon icon-twitter social-icon" aria-hidden="true" focusable="false">
                                    <use
                                        href="/assets/cardif/iccrea/wp-content/themes/bnpp-cardif-v2c/dist/icons/social.svg#icon-twitter">
                                    </use>
                                </svg> <span class="social__label"></span>
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</div>