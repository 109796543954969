<app-headerbanner [title]="titlebanner" [description]="descriptionbanner" [headerBannerCustom]="backgroundImageClass"></app-headerbanner>


<div class="col-12" id="main-content" role="main">
    <div class="portlet-layout row-fluid">
        <div class="portlet-column portlet-column-only span12" id="column-1">
            <div class="portlet-dropzone portlet-column-content portlet-column-content-only"
                id="layout-column_column-1">
                <div class="portlet-boundary portlet-boundary_73_ portlet-static portlet-static-end portlet-borderless portlet-breadcrumb "
                    id="p_p_id_73_INSTANCE_Tk7dOW2GWle8_"> <span id="p_73_INSTANCE_Tk7dOW2GWle8"></span>
                    <div class="portlet-borderless-container">
                        <div class="portlet-body col-10 offset-1 d-flex justify-content-between">
                            <ul aria-label="Barra di Navigazione" class=" breadcrumb breadcrumb-horizontal" style="margin: 0 auto 15px 0;">
                                <li class="first"><a href="https://bnpparibascardif.it/prodotti">Prodotti</a><span
                                        class="divider">/</span></li>
                                <li class="active last"><a>Set informativi</a><span class="divider">/</span></li>
                            </ul>
                            <div class="advanced-search-button">
                                <span class="txt-remove" (click)="toggleVisibleFilterColumns()">
                                    Ricerca avanzata
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="portlet-boundary portlet-boundary_ddlsearch_WAR_bpccsfctddlexportportlet_ portlet-static portlet-static-end portlet-borderless "
                    id="p_p_id_ddlsearch_WAR_bpccsfctddlexportportlet_"> <span
                        id="p_ddlsearch_WAR_bpccsfctddlexportportlet"></span>
                    <div class="portlet-borderless-container">
                        <div class="portlet-body">
                            <div class="col-10 offset-1">


                                <!--    Tabella -->
                                <app-tablesetinformativi
                                    [visibleFilterColumns]="visibleFilterColumns">
                                </app-tablesetinformativi>


                            </div>
                            <div id="aui_popup_content"> </div>
                        </div>
                    </div>
                </div>
                <div class="portlet-boundary portlet-boundary_csfnavigation_WAR_bpccsfctnavigationportlet_ portlet-static portlet-static-end portlet-borderless "
                    id="p_p_id_csfnavigation_WAR_bpccsfctnavigationportlet_"> <span
                        id="p_csfnavigation_WAR_bpccsfctnavigationportlet"></span>
                    <div class="portlet-borderless-container">
                        <div class="portlet-body">
                            <div class="content-nav"> <a class="prev fa-solid" href="https://valorizzazioni.bnpparibascardif.it/valorizzazioni">
                                    <span> Valorizzazioni </span> </a> <a class="next fa-solid"
                                    href="https://docs.fairmat.com/bnpparibascardif/products"> <span> Rendimenti Passati
                                    </span> </a> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>