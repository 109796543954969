import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keyTolabel'
})
export class KeyTolabelPipe implements PipeTransform {

  transform(value: any, enumCustom: any): any {
    for (const enumItem in enumCustom) {
      if(value === enumItem) {
        return enumCustom[enumItem]
      }
    }
  }

}

