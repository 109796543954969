<div id="prefooter" class="footer__pre">
	<div class="blocks-container">

		<figure class="wp-block-image size-full is-resized"><img loading="lazy" decoding="async" width="643" height="17"
				src="/assets/cardif/italy/wp-content/uploads/sites/17/2024/11/down-bar-1.webp"
				alt="" class="wp-image-886" style="width: 756px; height: auto; opacity: 1;"
				srcset="/assets/cardif/italy/wp-content/uploads/sites/17/2024/11/down-bar-1.webp 643w, /assets/cardif/italy/wp-content/uploads/sites/17/2024/11/down-bar-1.webp?resize=300,8 300w, /assets/cardif/italy/wp-content/uploads/sites/17/2024/11/down-bar-1.webp?resize=380,10 380w, /assets/cardif/italy/wp-content/uploads/sites/17/2024/11/down-bar-1.webp?resize=630,17 630w"
				sizes="auto, (max-width: 643px) 100vw, 643px"></figure>



		<p class="has-text-align-center has-medium-font-size" style="font-style:normal;font-weight:900"><mark
				class="has-inline-color has-dark-green-color"><a
					href="{{LINK.FAQ.href}}" data-type="page" data-id="18">{{LINK.FAQ.label}}</a>
				| <a href="{{LINK.RECLAMI.href}}" data-type="page"
					data-id="22">{{LINK.RECLAMI.label}}</a> | <a href="{{LINK.CONTATTACI.href}}"
					data-type="page" data-id="879">{{LINK.CONTATTACI.label}}</a></mark></p>
	</div>
</div>