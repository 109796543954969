import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-sharefooter',
  templateUrl: './sharefooter.component.html'
})
export class SharefooterComponent implements OnInit {

  constructor(private router: Router) { }

  
  urlMail: string = "";

  ngOnInit(): void {
    this.setPathType();
  }

  setPathType() {
    const currentUrl = this.router.url;
    let body: string = "";
    let subject: string = "";
    if (currentUrl.includes('/valorizzazioni')) {
      body = "https://valorizzazioni.bnpparibascardif.it/";
      subject = encodeURI("Quotazioni BNP Paribas Cardif Italia");
    } else if (currentUrl.includes('/set-informativi')) {
      body = "https://setinformativi.bnpparibascardif.it/";
      subject = encodeURI("Set Informativi BNP Paribas Cardif Italia");
    }
    this.urlMail = "mailto:?body="+body+"&amp;subject="+subject;
  }

}