<!--Hero home -->
<app-hero-home [hero__title]="hero__title" [hero__baseline]="hero__baseline" [hero__picture_url]="hero__picture_url"></app-hero-home>
<!-- Content body -->
<div class="aui ltr yui3-js-enabled webkit ltr js chrome chrome109 chrome109-0 win secure">
    <div class="blocks-container">
        <div class="wp-block-columns alignfull is-layout-flex wp-container-core-columns-is-layout-1 wp-block-columns-is-layout-flex">
            <div class="wp-block-column is-vertically-aligned-top is-layout-flow wp-block-column-is-layout-flow flex-basis">
                <main class="col-12" id="main-content">
                    <div class="portlet-layout row-fluid">
                        <div class="portlet-column portlet-column-only span12" id="column-1">
                            <div class="portlet-dropzone portlet-column-content portlet-column-content-only"
                                id="layout-column_column-1">


                                                <div class="col-10 offset-1 mb-5">
                                                    <div class="editor-content">
                                                        <p>In questa area trovi i set informativi dei nostri prodotti assicurativi. 
                                                            Inserisci il nome del tuo prodotto o un’altra parola chiave nel campo di ricerca per trovare 
                                                            l’edizione del set informativo di tuo riferimento o utilizza la ricerca avanzata.
                                                        </p>
                                                    </div>
                                                </div>    
                                <div class="d-flex justify-content-end">
                                    <div class="advanced-search-button mb-3">
                                        <a class="txt-remove text-decoration-underline" (click)="toggleVisibleFilterColumns()">
                                            Ricerca avanzata
                                        </a>
                                    </div>
                                </div>
                                <div class="portlet-boundary portlet-boundary_ddlsearch_WAR_bpccsfctddlexportportlet_ portlet-static portlet-static-end portlet-borderless "
                                    id="p_p_id_ddlsearch_WAR_bpccsfctddlexportportlet_"> <span
                                        id="p_ddlsearch_WAR_bpccsfctddlexportportlet"></span>
                                    <div class="portlet-borderless-container">
                                        <div class="portlet-body">
                                                            <div class="col-12">


                                                <!--    Tabella -->
                                                <app-tablesetinformativi
                                                    [visibleFilterColumns]="visibleFilterColumns">
                                                </app-tablesetinformativi>


                                            </div>
                                            <div id="aui_popup_content"> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </div>
</div>