<div class="footer__bottom">
    <div class="container footer__container-bottom">
        <div class="header__left">
            <a href="{{LINK.logo.href}}" class="header__logo-link justify-content-lg-start justify-content-center">
                <div class="header__title visuallyhidden">BNP Paribas</div>
                <img class="header__logo"
                alt=""
                    src="/assets/cardif/images/logo-bnp.svg"
                    width="165" height="34">
            </a>
        </div>
        <nav class="header__right" role="navigation" aria-label="Footer sub navigation">
        </nav>
    </div>
</div>