import { AfterViewInit, Component, Renderer2 } from '@angular/core';
import { ScriptLoaderService } from './services/script-loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements AfterViewInit{
  title = 'cardif-set-informativi-fe';

  //Lista script da caricare dopo il caricameto della pagina
  listScripts:any = [
    "/assets/cardif/js/static2.js"
  ];

  constructor(
    public renderer: Renderer2,
    public scriptLoaderService:ScriptLoaderService
  ){}

  ngAfterViewInit(): void {
    this.listScripts.forEach((element:string) => {
      this.scriptLoaderService.loadJsScript(this.renderer,element);
    });
  }
}
