export const environment = {
  production: false,
  backend:{
    baseUrl_setInformativi: "/informative-sets-api/",
    baseUrl_valorizzazioni: "/api/"
  },
  baseUrl: {
    valorizzazioni: "https://valorizzazioni.staging.bnpparibascardif.it/",
    setinformativi: "https://setinformativi.staging.bnpparibascardif.it/"
  }
};
