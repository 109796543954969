import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API, COMMON_PATH_API } from 'src/app/config/api.conf';
import { IPagedModelCompanyModel } from 'src/app/interface/api-setinformativi.interface';
import { environment } from 'src/environments/environment';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(
    private readonly http: HttpClient,
    private readonly utils: UtilsService
  ) { }
  pathUrl: string = COMMON_PATH_API;
  baseUrl:string = environment.backend.baseUrl_setInformativi;
  url:string = this.baseUrl + this.pathUrl;

  search(request:any):Observable<IPagedModelCompanyModel>{
    let url = this.url+API.company.search;
    let params = this.utils.createParams(request);
    return this.http.get<IPagedModelCompanyModel>(url,{ params: params });
  }
}
