<app-headerbanner [title]="titlebanner" [description]="descriptionbanner"
    [headerBannerCustom]="backgroundImageClass"></app-headerbanner>

<div class="col-12" id="main-content" role="main">
    <div class="portlet-layout row-fluid">
        <div class="portlet-column portlet-column-only span12" id="column-1">
            <div class="portlet-dropzone portlet-column-content portlet-column-content-only"
                id="layout-column_column-1">
                <div class="portlet-boundary portlet-boundary_73_ portlet-static portlet-static-end portlet-borderless portlet-breadcrumb "
                    id="p_p_id_73_INSTANCE_Tk7dOW2GWle8_"> <span id="p_73_INSTANCE_Tk7dOW2GWle8"></span>
                    <div class="portlet-borderless-container">
                        <div class="portlet-body col-md-2 col-sx-12 offset-2 d-flex justify-content-between">
                            <ul aria-label="Barra di Navigazione" class=" breadcrumb breadcrumb-horizontal"
                                style="margin: 0 auto 15px 0;">
                                <li class="first"><a href="https://bnpparibascardif.it/prodotti">Prodotti</a><span
                                        class="divider">/</span></li>
                                <li class="active last"><a>Valorizzazioni</a><span class="divider">/</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="portlet-boundary portlet-boundary_ddlsearch_WAR_bpccsfctddlexportportlet_ portlet-static portlet-static-end portlet-borderless "
                    id="p_p_id_ddlsearch_WAR_bpccsfctddlexportportlet_"> <span
                        id="p_ddlsearch_WAR_bpccsfctddlexportportlet"></span>
                    <div class="portlet-borderless-container">
                        <div class="portlet-body">
                            <div class="col-10 offset-1">


                                <div class="editor-content">
                                    <h1>Valorizzazioni</h1>
                                    <p><strong>In quest'area sono consultabili le valorizzazioni degli strumenti
                                            finanziari collegati alle polizze della Compagnia. L'aggiornamento delle
                                            informazioni avviene con la medesima frequenza indicata nelle condizioni di
                                            polizza di ogni prodotto, a cui sono collegati gli strumenti finanziari di
                                            seguito pubblicati.</strong></p>
                                    <p>Nel box di consultazione puoi visualizzare le valorizzazioni di tuo interesse.
                                        Cliccando sulla freccia in prossimità della tipologia di strumento finanziario,
                                        legato alla polizza da te sottoscritta (Fondi Unit Linked/Index linked/ISIN) aprirai
                                        l'elenco in ordine alfabetico. Selezionando singolarmente lo strumento di tuo
                                        interesse potrai visualizzarne i dettagli.</p>
                                </div>


                            </div>
                            <div class="filter-input">
                                <div class="col-md-2 col-sx-12 offset-5">
                                    <label *ngIf="fondiSelection_Selected.length > 0">Fondi Unit Linked</label>
                                    <ng-autocomplete #fondiSelection placeholder="Fondi Unit Linked..." [focusFirst]="true"
                                        [data]="fondiSelection_Suggestion" [searchKeyword]="fondi_Keyword"
                                        (selected)='selectEvent__fondiSelection($event)'
                                        (inputChanged)='getList_fondiSelection($event)'
                                        (inputFocused)='onFocused__fondiSelection($event)'
                                        (inputCleared)="searchCleared__fondiSelection()"
                                        [itemTemplate]="itemTemplate_fondiSelection"
                                        [notFoundTemplate]="notFoundTemplate_fondiSelection" [debounceTime]="600"
                                        [isLoading]="fondiSelection_isKeywordLoadingResult" [minQueryLength]="1">
                                    </ng-autocomplete>
                                    <ng-template #itemTemplate_fondiSelection let-item>
                                        <div class="col-12">
                                            <div class="mb-1 mx-3 me-0">
                                                <small [innerHTML]="item.name"></small>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template #notFoundTemplate_fondiSelection let-notFound>
                                        {{fondiSelection_errorMsg}}
                                    </ng-template>
                                </div>
                            </div>
                            <div class="filter-input my-4">
                                <div class="col-md-2 col-sx-12 offset-5">
                                    <label *ngIf="indexSelection_Selected.length > 0">Index Linked</label>
                                    <ng-autocomplete #indexSelection placeholder="Index Linked..." [focusFirst]="true"
                                        [data]="indexSelection_Suggestion" [searchKeyword]="index_Keyword"
                                        (selected)='selectEvent__indexSelection($event)'
                                        (inputChanged)='getList_indexSelection($event)'
                                        (inputFocused)='onFocused__indexSelection($event)'
                                        (inputCleared)="searchCleared__indexSelection()"
                                        [itemTemplate]="itemTemplate_indexSelection"
                                        [notFoundTemplate]="notFoundTemplate_indexSelection" [debounceTime]="600"
                                        [isLoading]="indexSelection_isKeywordLoadingResult" [minQueryLength]="1">
                                    </ng-autocomplete>
                                    <ng-template #itemTemplate_indexSelection let-item>
                                        <div class="col-12">
                                            <div class="mb-1 mx-3 me-0">
                                                <small [innerHTML]="item.name"></small>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template #notFoundTemplate_indexSelection let-notFound>
                                        {{indexSelection_errorMsg}}
                                    </ng-template>
                                </div>
                            </div>
                            <div class="filter-input my-4">
                                <div class="col-md-2 col-sx-12 offset-5">
                                    <label *ngIf="isinSelection_Selected.length > 0">Isin</label>
                                    <ng-autocomplete #isinSelection placeholder="ISIN..." [focusFirst]="true"
                                        [data]="isinSelection_Suggestion" [searchKeyword]="isin_Keyword"
                                        (selected)='selectEvent__isinSelection($event)'
                                        (inputChanged)='getList_isinSelection($event)'
                                        (inputFocused)='onFocused__isinSelection($event)'
                                        (inputCleared)="searchCleared__isinSelection()"
                                        [itemTemplate]="itemTemplate_isinSelection"
                                        [notFoundTemplate]="notFoundTemplate_isinSelection" [debounceTime]="600"
                                        [isLoading]="isinSelection_isKeywordLoadingResult" [minQueryLength]="1">
                                    </ng-autocomplete>
                                    <ng-template #itemTemplate_isinSelection let-item>
                                        <div class="col-12">
                                            <div class="mb-1 mx-3 me-0">
                                                <small [innerHTML]="item.isin + ' - ' + item.name"></small>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template #notFoundTemplate_isinSelection let-notFound>
                                        {{isinSelection_errorMsg}}
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        <div class="container-nav my-5" *ngIf="showChartSection">
                            <div class="col-md-8 col-xs-12 offset-2 block-info">
                                <div class="block-info_detail">
                                    <ul>
                                        <li *ngFor="let item of blockInfo | keyvalue"><b>{{item.key | keyTolabel:
                                                infoNavEnum }}</b>:{{item.value}}</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-8 col-xs-12 offset-2 block-filter">
                                <div class="block-filter_detail" ngbRadioGroup [formGroup]="filterDateForm">
                                    <ul>
                                        <li><b>Evoluzione Nav:</b> </li>
                                        <ng-container *ngFor="let singleDate of blockFilter">
                                            <ng-container *ngFor="let date of singleDate | keyvalue">
                                                <li>
                                                    <input type="radio" (change)="handleChange(date.value)" [id]="date.key" formControlName="filterNav"
                                                        [value]="date.value">
                                                </li>
                                                <label [ngClass]="date.value === selectedFilter.value  ? 'active': 'not_active'" [for]="date.key">{{date.key}}</label>
                                            </ng-container>
                                        </ng-container>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-8 col-xs-12 offset-2 block-chart">
                                <div class="chart-nav" >
                                    <canvas baseChart [type]="'line'" [data]="lineChartData"
                                        [options]="lineChartOptions" [legend]="lineChartLegend">
                                    </canvas>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="portlet-boundary portlet-boundary_csfnavigation_WAR_bpccsfctnavigationportlet_ portlet-static portlet-static-end portlet-borderless "
                    id="p_p_id_csfnavigation_WAR_bpccsfctnavigationportlet_"> <span
                        id="p_csfnavigation_WAR_bpccsfctnavigationportlet"></span>
                    <div class="portlet-borderless-container">
                        <div class="portlet-body">
                            <div class="content-nav"> <a class="prev fa-solid"
                                    href="https://bnpparibascardif.it/previdenza-complementare">
                                    <span> Previdenza complementare </span> </a> <a class="next fa-solid"
                                    href="https://setinformativi.bnpparibascardif.it/"> <span> Set Informativi
                                    </span> </a> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>