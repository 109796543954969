<div class="portlet-boundary portlet-boundary_101_ portlet-static portlet-static-end portlet-borderless portlet-asset-publisher "
    id="p_p_id_101_INSTANCE_headerContent_"> <span id="p_101_INSTANCE_headerContent"></span>
    <div class="portlet-borderless-container">
        <div class="portlet-body">
            <div class="subscribe-action"> </div> 
            <div class="header-banner">
                <div [ngClass]="headerBannerCustom">
                    <div class="wrapper">
                        <div class="textbox">
                            <h2>{{title}}</h2>
                            <p>{{description}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>