<!--Hero home -->
<app-hero-home [hero__title]="hero__title" [hero__baseline]="hero__baseline" [hero__picture_url]="hero__picture_url"></app-hero-home>
<!-- Content body -->
<div class="aui ltr yui3-js-enabled webkit ltr js chrome chrome109 chrome109-0 win secure">
    <div class="blocks-container">
        <div class="wp-block-columns alignfull is-layout-flex wp-container-core-columns-is-layout-1 wp-block-columns-is-layout-flex">
            <div class="wp-block-column is-vertically-aligned-top is-layout-flow wp-block-column-is-layout-flow" style="flex-basis:100%">
                <main class="col-12" id="main-content">
                    <div class="portlet-layout row-fluid">
                        <div class="portlet-column portlet-column-only span12" id="column-1">
                            <div class="portlet-dropzone portlet-column-content portlet-column-content-only"
                                id="layout-column_column-1">
                                

                                <div class="col-10 offset-1 mb-5">
                                    <div class="editor-content">
                                        <p>
                                            In quest’area puoi consultare le quotazioni degli strumenti finanziari collegati alle tue polizze.
                                            L’aggiornamento delle informazioni avviene con la medesima frequenza indicata nelle 
                                            condizioni di polizza di ogni prodotto a cui sono collegati gli strumenti finanziari.
                                        </p>
                                        <p>
                                            Inserisci il nome del fondo oppure l’ISIN di tuo interesse per
                                            visualizzarne le quotazioni. Puoi anche ricercare i fondi tramite
                                            parole chiave e selezionare quello che ti interessa.
                                        </p>
                                    </div>
                                </div>
                                

                                
                                <div class="portlet-boundary portlet-boundary_ddlsearch_WAR_bpccsfctddlexportportlet_ portlet-static portlet-static-end portlet-borderless "
                                    id="p_p_id_ddlsearch_WAR_bpccsfctddlexportportlet_"> <span
                                        id="p_ddlsearch_WAR_bpccsfctddlexportportlet"></span>
                                    <div class="portlet-borderless-container">
                                        <div class="portlet-body">
                                            <div class="filter-input">
                                                <div class="col-lg-4 col-md-4 col-sm-12  col-xs-12 col-10 offset-lg-4 offset-md-4 offset-sm-4 offset-1">
                                                    <label *ngIf="fondiSelection_Selected.length > 0" for="fondiSelection">Fondi Unit Linked</label>
                                                    <ng-autocomplete #fondiSelection id="fondiSelection" placeholder="Fondi Unit Linked..." [focusFirst]="true"
                                                        [data]="fondiSelection_Suggestion" [searchKeyword]="fondi_Keyword"
                                                        (selected)='selectEvent__fondiSelection($event)'
                                                        (inputChanged)='getList_fondiSelection($event)'
                                                        (inputFocused)='onFocused__fondiSelection($event)'
                                                        (inputCleared)="searchCleared__fondiSelection()"
                                                        [itemTemplate]="itemTemplate_fondiSelection"
                                                        [notFoundTemplate]="notFoundTemplate_fondiSelection" [debounceTime]="600"
                                                        [isLoading]="fondiSelection_isKeywordLoadingResult" [minQueryLength]="1">
                                                    </ng-autocomplete>
                                                    <ng-template #itemTemplate_fondiSelection let-item>
                                                        <div class="col-12">
                                                            <div class="mb-1 mx-3 me-0">
                                                                <small [innerHTML]="item.name"></small>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                    <ng-template #notFoundTemplate_fondiSelection let-notFound>
                                                        {{fondiSelection_errorMsg}}
                                                    </ng-template>
                                                </div>
                                            </div>
                                            <div class="filter-input my-4">
                                                <div class="col-lg-4 col-md-4 col-sm-12  col-xs-12 col-10 offset-lg-4 offset-md-4 offset-sm-4 offset-1">
                                                    <label *ngIf="indexSelection_Selected.length > 0" for="indexSelection">Index Linked</label>
                                                    <ng-autocomplete #indexSelection id="indexSelection" placeholder="Index Linked..." [focusFirst]="true"
                                                        [data]="indexSelection_Suggestion" [searchKeyword]="index_Keyword"
                                                        (selected)='selectEvent__indexSelection($event)'
                                                        (inputChanged)='getList_indexSelection($event)'
                                                        (inputFocused)='onFocused__indexSelection($event)'
                                                        (inputCleared)="searchCleared__indexSelection()"
                                                        [itemTemplate]="itemTemplate_indexSelection"
                                                        [notFoundTemplate]="notFoundTemplate_indexSelection" [debounceTime]="600"
                                                        [isLoading]="indexSelection_isKeywordLoadingResult" [minQueryLength]="1">
                                                    </ng-autocomplete>
                                                    <ng-template #itemTemplate_indexSelection let-item>
                                                        <div class="col-12">
                                                            <div class="mb-1 mx-3 me-0">
                                                                <small [innerHTML]="item.name"></small>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                    <ng-template #notFoundTemplate_indexSelection let-notFound>
                                                        {{indexSelection_errorMsg}}
                                                    </ng-template>
                                                </div>
                                            </div>
                                            <div class="filter-input my-4">
                                                <div class="col-lg-4 col-md-4 col-sm-12  col-xs-12 col-10 offset-lg-4 offset-md-4 offset-sm-4 offset-1">
                                                    <label *ngIf="isinSelection_Selected.length > 0" for="isinSelection">Isin</label>
                                                    <ng-autocomplete #isinSelection id="isinSelection" placeholder="ISIN..." [focusFirst]="true"
                                                        [data]="isinSelection_Suggestion" [searchKeyword]="isin_Keyword"
                                                        (selected)='selectEvent__isinSelection($event)'
                                                        (inputChanged)='getList_isinSelection($event)'
                                                        (inputFocused)='onFocused__isinSelection($event)'
                                                        (inputCleared)="searchCleared__isinSelection()"
                                                        [itemTemplate]="itemTemplate_isinSelection"
                                                        [notFoundTemplate]="notFoundTemplate_isinSelection" [debounceTime]="600"
                                                        [isLoading]="isinSelection_isKeywordLoadingResult" [minQueryLength]="1">
                                                    </ng-autocomplete>
                                                    <ng-template #itemTemplate_isinSelection let-item>
                                                        <div class="col-12">
                                                            <div class="mb-1 mx-3 me-0">
                                                                <small [innerHTML]="item.isin + ' - ' + item.name"></small>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                    <ng-template #notFoundTemplate_isinSelection let-notFound>
                                                        {{isinSelection_errorMsg}}
                                                    </ng-template>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container-nav my-5" *ngIf="showChartSection">
                                            <div class="col-md-10 col-xs-12 offset-1 block-info">
                                                <div class="block-info_detail">
                                                    <ul class="p-0">
                                                        <li class="row" *ngFor="let item of blockInfo">
                                                            <div class="col-lg-2 col-md-3 col-sm-12 col-12 offset-lg-3">
                                                                    <b>{{item.label}}</b>:
                                                            </div>
                                                            <div class="col-lg-6 col-md-9 col-sm-12 col-12">
                                                                    {{item.value}}
                                                            </div>      
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="col-md-8 col-xs-12 offset-2 block-filter">
                                                <div class="block-filter_detail" ngbRadioGroup [formGroup]="filterDateForm">
                                                    <ul>
                                                        <li><b>Evoluzione Nav:</b> </li>
                                                        <ng-container *ngFor="let singleDate of blockFilter">
                                                            <ng-container *ngFor="let date of singleDate | keyvalue">
                                                                <li>
                                                                    <input type="radio" (change)="handleChange(date.value)" [id]="date.key" formControlName="filterNav"
                                                                        [value]="date.value">
                                                                </li>
                                                                <label [ngClass]="date.value === selectedFilter.value  ? 'active': 'not_active'" [for]="date.key">{{date.key}}</label>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="col-md-8 col-xs-12 offset-2 block-chart">
                                                <div class="chart-nav" >
                                                    <canvas baseChart [type]="'line'" [data]="lineChartData"
                                                        [options]="lineChartOptions" [legend]="lineChartLegend">
                                                    </canvas>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </div>
</div>