<div class="header__main">
    <div class="container">
        <div class="header__wrapper">
            <div class="header__progressbar" style="--progress: 0.000;"></div>
            <div class="header__top">
                <div class="header__left">
                    <a href="{{LINK.logo.href}}" class="header__logo-link">
                        <div class="header__title visuallyhidden">BNP Paribas</div>
                        <img class="header__logo"
                            alt=""
                            src="/assets/cardif/images/logo-bnp.svg"
                            width="165" height="34">
                    </a>
                </div>
                <div class="header__right">

                    <nav id="nav-lang-switcher" class="amenu nav lang-switcher" aria-label="Cambia la lingua"
                        role="navigation">
                        <ul id="amenu-lang-switcher" class="menu amenu__lang-switcher js-amenu__lang-switcher">
                            <li class="menu-item current-menu-item menu-item-has-children amenu__top">
                                <a href="#" id="amenu-1737447335261-6"
                                    aria-controls="amenu-1737447335261-7" aria-expanded="false" tabindex="0">
                                    <span>it<span class="sr-only">Italiano</span></span><svg class="icon icon-down"
                                        aria-hidden="true" focusable="false">
                                        <use
                                            href="/assets/cardif/iccrea/wp-content/themes/bnpp-cardif-v2c/dist/icons/sprite.svg#icon-down">
                                        </use>
                                    </svg> </a>
                                <div class="amenu__panel" id="amenu-1737447335261-7" aria-hidden="true"
                                    aria-labelledby="amenu-1737447335261-6">
                                    <ul class="sub-menu amenu__sub-menu">
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </nav>
                    <button id="search__toggle" class="search__toggle" aria-expanded="false"
                        aria-controls="js-searchform">
                        <span class="sr-only">Cerca</span>
                        <svg class="icon icon-search search__icon" aria-hidden="true" focusable="false">
                            <use
                                href="/assets/cardif/iccrea/wp-content/themes/bnpp-cardif-v2c/dist/icons/sprite.svg#icon-search">
                            </use>
                        </svg> <svg class="icon icon-close search__icon" aria-hidden="true" focusable="false">
                            <use
                                href="/assets/cardif/iccrea/wp-content/themes/bnpp-cardif-v2c/dist/icons/sprite.svg#icon-close">
                            </use>
                        </svg> </button>
                    <div class="searchform" id="js-searchform">
                        <form class="form" autocomplete="off" action="https://bnpparibascardif.it/">
                            <div class="form__row"><svg class="icon" aria-hidden="true" focusable="false">
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink"
                                        href="/assets/cardif/iccrea/wp-content/themes/bnpp-cardif-v2c/dist/icons/sprite.svg#icon-search">
                                    </use>
                                </svg><label for="search" class="visuallyhidden">Inserisci i termini da
                                    cercare</label><input type="search" id="search" class="searchform__field"
                                    placeholder="Cosa stai cercando?" title="Cosa stai cercando?" name="s"
                                    autocomplete="off" required=""><button type="submit"
                                    class="button-text form__submit btn btn--primary">Cerca</button></div>
                        </form>
                    </div>
                </div>
            </div>
            <nav id="nav-primary" class="amenu nav-primary header__bottom" aria-label="Main navigation"
                role="navigation">
                <button id="amenu__toggle" class="amenu__toggle accessible-megamenu-toggle" aria-expanded="false"
                    aria-controls="amenu-main">
                    <span class="visuallyhidden">Menu</span>
                    <svg class="icon icon-menu" aria-hidden="true" focusable="false">
                        <use
                            href="/assets/cardif/iccrea/wp-content/themes/bnpp-cardif-v2c/dist/icons/sprite.svg#icon-menu">
                        </use>
                    </svg> <svg class="icon icon-close" aria-hidden="true" focusable="false">
                        <use
                            href="/assets/cardif/iccrea/wp-content/themes/bnpp-cardif-v2c/dist/icons/sprite.svg#icon-close">
                        </use>
                    </svg> </button>
                <ul id="amenu-main" class="menu amenu__main js-amenu__main">
                    <li id="menu-item-825"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-825 amenu__top"><a
                            href="https://bnpparibascardif.it/protezione/"
                            id="amenu-1737447335254-1">{{LINK.Protezione.label}}</a></li>
                    <li id="menu-item-42"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-42 amenu__top"><a
                            href="https://bnpparibascardif.it/risparmio-e-investimento/"
                            id="amenu-1737447335255-2">{{LINK.RisparmioeInvestimento.label}}</a></li>
                    <li id="menu-item-40"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-40 amenu__top"><a
                            href="https://bnpparibascardif.it/previdenza/"
                            id="amenu-1737447335256-3">{{LINK.Previdenza.label}}</a></li>
                    <li class="menu-item menu-item-has-children amenu__top">
                        <div class="amenu__panel alwaysopen" id="amenu-1737447335257-5" aria-hidden="true">
                            <ul class="sub-menu sub-menu-special">
                                <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-55"><a
                                        href="{{LINK.chisiamo.href}}">
                                        {{LINK.chisiamo.label}}
                                    </a>
                                </li>
                                <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-216"><a
                                        href="{{LINK.MyCardif.href}}">
                                        {{LINK.MyCardif.label}}
                                    </a>
                                </li>
                                <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-56"><a
                                        href="{{LINK.Contattaci.href}}">
                                        {{LINK.Contattaci.label}}
                                    </a>
                                </li>
                                
                                <li class="nav" style="display:block">
                                    <app-menu-social></app-menu-social>
                                </li>
                                <li id="nav-lang-pre" class="nav" style="display:block">
                                    <ul class="menu lang__menu" aria-label="Cambia la lingua">
                                        <li class="lang-item lang-item-2 lang-item-it current-lang lang-item-first"><a
                                                lang="it-IT" hreflang="it-IT"
                                                href="https://bnpparibascardif.it/">it</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>